import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment.prod';
import {nfeDTO} from 'src/model/dto/nfeDTO';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {StatesProvider} from '../basicos/states';
import {Snackbar} from '../../util/snackbar';
import {ConsultaPdfXml} from './consultaPdfXml';
import { PdfViewerComponent } from 'src/app/routes/notafiscal/pdf-viewer/pdf-viewer.component';

@Injectable()
export class nfeJsonProvider {

  constructor(
    public http: HttpClient,
    public oauthService: OAuthService,
    public modalService: NgbModal,
    public statesProvider: StatesProvider,
    public snackBar: Snackbar,
    public consultaPdfXml: ConsultaPdfXml) {
  }

  public nfe: nfeDTO
  setJson(emissor, naturezaSelecionada, enderecoEmissorSelecionado,destinatarioSelecionado,
    enderecoDestinatarioSelecionado,seventhFormGroup,chaves,faturamento, fatura, parcelas,produtos,fourthFormGroup,pagamentoFormGroup,freteInfo,indFinal,deliveryAddress
   , receivingAddress, idEndereco, preview, sixFormGroup, sendEmail, sendEmailClient)
{
  return new Promise ((resolve, reject) =>{
try{
    //console.log("INFOS ADICIONAIS", sixFormGroup);
    //console.log("INFOS GERAIS", fourthFormGroup);
  let emissorObj
        //############################ Emissor ################,
        emissorObj = {
          id: enderecoEmissorSelecionado.id,
          ie: enderecoEmissorSelecionado.inscricaoEstadual,
          issuerEmail: emissor[0].email,
          sendEmail : sendEmail,
          sendEmailDest: sendEmailClient,
          platformId: 'APP_WEB'
        }


      //########################## CLIENTE ####################### gg
      let clienteObj: any = {
        uf: enderecoDestinatarioSelecionado.ufName,
        email: destinatarioSelecionado.email,
        cep: enderecoDestinatarioSelecionado.cep,
        substituto_tributario: destinatarioSelecionado.taxSubstitute,
        endereco: enderecoDestinatarioSelecionado.logradouro,
        numero: enderecoDestinatarioSelecionado.numero,
        bairro: enderecoDestinatarioSelecionado.bairro,
        cidade: enderecoDestinatarioSelecionado.municipioName,
        telefone: enderecoDestinatarioSelecionado.telefone,
        complemento: enderecoDestinatarioSelecionado.complemento
      };

      if (destinatarioSelecionado.foreignId) {
        clienteObj['nome_estrangeiro'] = destinatarioSelecionado.nome;
        clienteObj['nome_pais'] = enderecoDestinatarioSelecionado.paisName;
        clienteObj['cod_pais'] = enderecoDestinatarioSelecionado.pais;
      } else if (destinatarioSelecionado.cpfCnpj.length == 14) {
        clienteObj['cnpj'] = destinatarioSelecionado.cpfCnpj;
        clienteObj['razao_social'] = destinatarioSelecionado.nome;
      } else if (destinatarioSelecionado.cpfCnpj.length == 11) {
        clienteObj['cpf'] = destinatarioSelecionado.cpfCnpj;
        clienteObj['nome_completo'] = destinatarioSelecionado.nome;
      }

      if (enderecoDestinatarioSelecionado.ieType == 'ICMS_TAXPAYER') {
        clienteObj['contribuinte'] = 1;
      } else if (enderecoDestinatarioSelecionado.ieType == 'EXEMPTED_TAXPAYER') {
        clienteObj['contribuinte'] = 2;
      } else if (enderecoDestinatarioSelecionado.ieType == 'NON_ICMS_TAXPAYER') {
        clienteObj['contribuinte'] = 9;
      } else {
        clienteObj['contribuinte'] = null;
      }

      if (enderecoDestinatarioSelecionado.ie) {
        clienteObj['ie'] = enderecoDestinatarioSelecionado.ie;
      }

      if (indFinal == false) {
        clienteObj['consumidor_final'] = 0;
      } else {
        clienteObj['consumidor_final'] = 1;
      }

      //########################### PEDIDO ########################
      let pagamento = [], forma_pagamento = [], valor_pagamento = [], desc_pagamento = [];
      pagamentoFormGroup.forEach(element => {
        forma_pagamento.push(element.MeioPagamento);
        valor_pagamento.push(element.MeioPagamento == '90' ? null : element.ValorPagamento.toString());
        pagamento.push(element.IndicadorFormaPagamento);
        desc_pagamento.push(element.desc_pagamento);

      });

      let pedidoObj: any = {
        pagamento: pagamento,
        forma_pagamento: forma_pagamento,
        valor_pagamento: valor_pagamento,
        desc_pagamento: desc_pagamento,
        presenca: fourthFormGroup.indPres,
        modalidade_frete: seventhFormGroup.modalidadeFrete,
        desconto: fourthFormGroup.valorTotalDesconto,
        frete: seventhFormGroup.totalFrete,
        informacoes_complementares: sixFormGroup.value.InfoCompl,
        informacoes_fisco: sixFormGroup.value.InfoAoFisco,
      };
      //########################## GERAL #######################
      const dtEntExt = fourthFormGroup.dtEntExt;
      const dtEmi = fourthFormGroup.dtEmi;

      //###################json

      let json = {
        operacao: naturezaSelecionada.tipoNum,
        natureza_operacao: naturezaSelecionada.descricao,
        modelo: 1,
        finalidade: naturezaSelecionada.finalidade == "NF-e normal" ? 1 : (naturezaSelecionada.finalidade == "NF-e complementar" ? 2 : 4),
        ambiente: 1,
        previa_danfe: preview,
        data_emissao: moment(dtEmi).format("YYYY-MM-DD HH:mm:ss"), // Emissão com data retroativa
        emissor: emissorObj,
        cliente: clienteObj,
        pedido: pedidoObj
     };
     dtEntExt ? json['data_entrada_saida'] = moment(dtEntExt).format("YYYY-MM-DD HH:mm:ss") : json['data_entrada_saida'] = "";

      //########################## PRODUTOS ####################### gg
      let produtosObj = [];
      produtos.forEach(element => {
        produtosObj.push({
          quantidade: element.comercialQt,
          unidade: element.comercialUn,
          quantidade_tributavel: element.tributacaoQt,
          unidade_tributavel: element.tributacaoUn,
          gtin: element.gtin,
          ncm: element.ncm,
          subtotal: element.unCommerceValue,
          nome: element.products.description,
          total: element.totalProdValue,
          codigo: element.products.code,
          origem: element.products.productOrigin.code,
          beneficio_fiscal: element.BeneficioFiscal,

          impostos: {
            icms: {
              codigo_cfop: element.cfop,
              situacao_tributaria: element.SituacaoTributaria,
              aliquota: element.Aliquota,
              aliquota_diferimento: element.AliquotaDiferimento == "0" ? "0.00" : element.AliquotaDiferimento,
              aliquota_diferimento_fcp: element.AliquotaDiferimentoFCP == "0" ? "0.00" : element.AliquotaDiferimentoFCP,
              aliquota_reducao: element.AliquotaReducao == "0" ? "0.0000" : element.AliquotaReducao,
              bc_st_retido: element.bc_st_retido == "0" ? "0.00" : element.bc_st_retido,
              aliquota_st_retido: element.aliquota_st_retido == "0" ? "0.00" : element.aliquota_st_retido,
              valor_st_retido: element.valor_st_retido == "0" ? "0.00" : element.valor_st_retido,
              bc_st_dest: element.bc_st_dest == "0" ? "0.00" : element.bc_st_dest,
              valor_st_dest: element.valor_st_dest == "0" ? "0.00" : element.valor_st_dest,
              valor_fcp_retido: element.valor_fcp_retido == "0" ? "0.00" : element.valor_fcp_retido,
              aliquota_fcp_retido: element.aliquota_fcp_retido == "0" ? "0.00" : element.aliquota_fcp_retido,
              aliquota_bc_efetivo: element.aliquota_bc_efetivo == "0" ? "0.00" : element.aliquota_bc_efetivo,
              aliquota_icms_efetivo: element.aliquota_icms_efetivo == "0" ? "0.00" : element.aliquota_icms_efetivo,
              aliquota_reducao_st: element.aliquota_reducao_st == "0" ? "0.00" : element.aliquota_reducao_st,
              aliquota_mva: element.aliquota_mva == "0" ? "0.00" : element.aliquota_mva,
              industria: element.industria == "0" ? "0.00" : element.industria,
              aliquota_st: element.aliquota_st == "0" ? "0.00" : element.aliquota_st,
              aliquota_fcp_st: element.aliquota_fcp_st == "0" ? "0.00" : element.aliquota_fcp_st,
              aliquota_credito: element.aliquota_credito == "0" ? "0.00" : element.aliquota_credito,
              valor_icms_substituto: element.valor_icms_substituto == "0" ? "0.00" : element.valor_icms_substituto,
            },
            pis: {
              situacao_tributaria: element.SituacaoTributariaPis,
              aliquota: element.AliquotaPercentual
            },
            cofins: {
              situacao_tributaria: element.SituacaoTributariaCofins,
              aliquota: element.cofAliquotaPercentual
            },
            ipi: {
              situacao_tributaria: element.SituacaoTributariaIpi,
              codigo_enquadramento: element.codigo_enquadramento,
              aliquota: element.AliquotaIpi === "0" ? "0.00" : element.AliquotaIpi,
              ...(
                json.finalidade === 4 ? {
                  ipi_devolvido: element.ipi_devolvido,
                  percentual_devolvido: element.percentual_devolvido
                } : {}
              )
            }
          }
        });
      });



      json['produtos'] = produtosObj;



      //########################## FATURAS ####################### gg
      if (faturamento) {
        let faturasObj = {
          numero: fatura.nFat,
          valor: fatura.vOrig,
          desconto: fatura.vDesc,
          valor_liquido: fatura.vLiq,
        };

        let parcelasObj = [];

        parcelas.forEach(element => {
          if (element.vDup) {
            parcelasObj.push({
              vencimento: element.dVenc,
              valor: element.vDup
            });
          }
          // i++;
        });

        json['fatura'] = faturasObj;
        json['parcelas'] = parcelasObj;
      }


      //########################## TRANSPORTE #######################
      if (seventhFormGroup.modalidadeFrete != 9) {
        let transporte = {
          volume: seventhFormGroup.qtdVolumes,
          especie: seventhFormGroup.especie,
          peso_bruto: seventhFormGroup.pesoBruto,
          peso_liquido: seventhFormGroup.pesoLiquido,
          endereco: seventhFormGroup.endereco,
          marca: seventhFormGroup.marca,
          lacres: seventhFormGroup.numeroLacres,
          numeracao: seventhFormGroup.numeracao,

        };

        if (freteInfo) {
          if (freteInfo.cpfCNPJ.length == 11) {
            transporte["cpf"] = freteInfo.cpfCNPJ;
            transporte["nome_completo"] = freteInfo.name;
          } else {
            transporte["cnpj"] = freteInfo.cpfCNPJ;
            transporte["razao_social"] = freteInfo.name;
          }

          transporte["ie"] = freteInfo.properties[0].ie;
          transporte["cidade"] = freteInfo.properties[0].cityName;
          transporte["cep"] = freteInfo.properties[0].cep;
          transporte["uf"] = freteInfo.properties[0].ufAbr;
          transporte["endereco"] = freteInfo.properties[0].street;
          transporte["placa"] = freteInfo.frete[0].placaVeiculo;
          transporte["rntc"] = freteInfo.frete[0].registroNacional;
          transporte["seguro"] = freteInfo.frete[0].valorSeguro;
          transporte["uf_veiculo"] = freteInfo.frete[0].ufVeiculoAbr;

          if (freteInfo.frete[0].reboque.length > 0) {
            transporte["reboque"] = [];
            freteInfo.frete[0].reboque.forEach((element) => {
              let obj = {
                placa: element.placaVeiculo,
                rntc: element.registroNacional,
                vagao: element.identificacaoVagao,
                balsa: element.identificacaoBalsa,
                uf_veiculo: element.uf_veiculoAbr,
              };
              transporte["reboque"].push(obj);
            });
          }
        }

        if (receivingAddress) {
          var enderecoRetirada: any = {
            uf: receivingAddress.properties[0].stateAbr,
            email: receivingAddress.email,
            cep: receivingAddress.properties[0].cep,
            substituto_tributario: receivingAddress.taxSubstitute,
            endereco: receivingAddress.properties[0].street,
            numero: receivingAddress.properties[0].houseNumber,
            bairro: receivingAddress.properties[0].neighborhood,
            cidade: receivingAddress.properties[0].cityName,
            telefone: receivingAddress.telephone,
            complemento: receivingAddress.properties[0].complement
          };
          if (receivingAddress.cpfCNPJ.length == 14) {
            enderecoRetirada['cnpj'] = receivingAddress.cpfCNPJ;
            enderecoRetirada['razao_social'] = receivingAddress.name;
          } else if (receivingAddress.cpfCNPJ.length == 11) {
            enderecoRetirada['cpf'] = receivingAddress.cpfCNPJ;
            enderecoRetirada['nome_completo'] = receivingAddress.name;
          }
          if (receivingAddress.properties[0].ieType == 'ICMS_TAXPAYER') {
            enderecoRetirada['contribuinte'] = 1;
          } else if (receivingAddress.properties[0].ieType == 'EXEMPTED_TAXPAYER') {
            enderecoRetirada['contribuinte'] = 2;
          } else {
            enderecoRetirada['contribuinte'] = 9;
          }
          if (receivingAddress.properties[0].ie) {
            enderecoRetirada['ie'] = receivingAddress.properties[0].ie;
          }
        }

        if (deliveryAddress) {
          var enderecoRecebimento = {
            uf: deliveryAddress.properties[0].stateAbr,
            email: deliveryAddress.email,
            cep: deliveryAddress.properties[0].cep,
            substituto_tributario: deliveryAddress.taxSubstitute,
            endereco: deliveryAddress.properties[0].street,
            numero: deliveryAddress.properties[0].houseNumber,
            bairro: deliveryAddress.properties[0].neighborhood,
            cidade: deliveryAddress.properties[0].cityName,
            telefone: deliveryAddress.telephone,
            complemento: deliveryAddress.properties[0].complement
          };

          if (deliveryAddress.foreignId) {
            enderecoRecebimento['nome_estrangeiro'] = deliveryAddress.name;
            enderecoRecebimento['nome_pais'] = deliveryAddress.properties[0].paisName;
            enderecoRecebimento['cod_pais'] = deliveryAddress.properties[0].pais;
          } else if (deliveryAddress.cpfCNPJ.length == 14) {
            enderecoRecebimento['cnpj'] = deliveryAddress.cpfCNPJ;
            enderecoRecebimento['razao_social'] = deliveryAddress.name;
          } else if (deliveryAddress.cpfCNPJ.length == 11) {
            enderecoRecebimento['cpf'] = deliveryAddress.cpfCNPJ;
            enderecoRecebimento['nome_completo'] = deliveryAddress.name;
          }

          if (deliveryAddress.properties[0].ieType == 'ICMS_TAXPAYER') {
            enderecoRecebimento['contribuinte'] = 1;
          } else if (deliveryAddress.properties[0].ieType == 'EXEMPTED_TAXPAYER') {
            enderecoRecebimento['contribuinte'] = 2;
          } else {
            enderecoRecebimento['contribuinte'] = 9;
          }

          if (deliveryAddress.properties[0].ie) {
            enderecoRecebimento["ie"] = deliveryAddress.properties[0].ie;
          }
        }

        json['transporte'] = transporte;
        json['transporte']['retirada'] = enderecoRetirada;
        json['transporte']['entrega'] = enderecoRecebimento;
      }
      //##### Referenciada
      let nfe_referenciada = [], ecf_referenciada = [], cte_referenciada = [], nf_rural_referenciada = [];
      if (chaves[0].referenciada == 1) {
        chaves[1].forEach(element => {
          nfe_referenciada.push(element.chave);
        });
        json['nfe_referenciada'] = nfe_referenciada;
      } else if (chaves[0].referenciada == 2) {
        chaves[3].forEach(element => {
          cte_referenciada.push(element.chave);
        });
        json['cte_referenciada'] = cte_referenciada;
      } else if (chaves[0].referenciada == 3) {
        chaves[4].forEach(element => {
          ecf_referenciada.push({
            modelo: element.modelo,
            n_ecf: element.ecf,
            n_coo: element.coo
          });
        });
        json['ecf_referenciada'] = ecf_referenciada;
      } else if (chaves[0].referenciada == 4) {
        chaves[5].forEach(element => {
          let obj = {
            uf: element.estado,
            data: moment(element.data).format("YYYY-MM-DD HH:mm:ss"),
            ie: element.ie,
            modelo: element.modelo,
            serie: element.serie,
            numero: element.numero
          };
          element.cpfCnpj.length == 14 ? obj['cnpj'] = element.cpfCnpj : obj['cpf'] = element.cpfCnpj;
          nf_rural_referenciada.push(obj);
        });
        json['nf_rural_referenciada'] = nf_rural_referenciada;
      }
      //##########################################
      this.postNfe(json, enderecoEmissorSelecionado.id).then((result: any) => {
        console.log("result", result);
        if (result.status == "aprovado") {
          this.getPdfModalDanfe(result.key);
          Swal.fire({
            title: 'Sucesso!',
            text: 'Nota Fiscal foi emitida!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            }
          });
        } else if (result.status == "processamento") {
          Swal.fire({
            title: 'Sucesso!',
            text: 'Nota Fiscal está em processamento!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            }
          });
        } else if (result.status == "previa_danfe") {
          this.getPdfModalPrevia(result.danfe);
          resolve(true);
        } else {
          Swal.fire({
            title: 'Falha!',
            text: result.description,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            }
          });
        }
      }).catch(err => {
        console.error(err);
        Swal.fire({
          title: 'Falha!',
          text: err.error,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            resolve(true);
          }
        });
      });
  } catch (err){
    reject(err);
  }
    });
  }

  setJsonDraft(req) {
    return new Promise((resolve, reject) => {
      // /* ############################ JSON ############################ */
      let json: any = {
        modelo: 1,
        previa_danfe: false,
        ambiente: 1
      };

      if (req.natureza) {
        json.operacao = req.natureza.tipoNum;
        json.natureza_operacao = req.natureza.descricao;
        json.finalidade = req.natureza.finalidade == "NF-e normal" ? 1 : (req.natureza.finalidade == "NF-e complementar" ? 2 : 4);
      }


      /* ############################ Emissor ############################ */
      let emissor: any = {};
      if (req.emissor) emissor.issuerEmail = req.emissor[0].email;
      if (req.sendEmail) emissor.sendEmail = req.sendEmail;
      if (req.enderecoEmissor) {
        emissor.id = req.enderecoEmissor.id;
        emissor.ie = req.enderecoEmissor.inscricaoEstadual;
      }
      json.emissor = emissor;


      /* ############################ Cliente ############################ */
      let cliente: any = {};
      // console.log(req.enderecoDestinatario.cep);
      if (req.enderecoDestinatario.cep) {
        cliente.uf = req.enderecoDestinatario.ufName;
        cliente.email = req.destinatario.email;
        cliente.cep = req.enderecoDestinatario.cep;
        cliente.endereco = req.enderecoDestinatario.logradouro;
        cliente.numero = req.enderecoDestinatario.numero;
        cliente.bairro = req.enderecoDestinatario.bairro;
        cliente.cidade = req.enderecoDestinatario.municipioName;
        cliente.telefone = req.enderecoDestinatario.telefone;
        cliente.complemento = req.enderecoDestinatario.complemento;
        cliente.substituto_tributario = req.destinatario.taxSubstitute;

        if (req.enderecoDestinatario.ieType == 'ICMS_TAXPAYER')
          cliente.contribuinte = 1;
        else if (req.enderecoDestinatario.ieType == 'EXEMPTED_TAXPAYER')
          cliente.contribuinte = 2;
        else if (req.enderecoDestinatario.ieType == "NON_ICMS_TAXPAYER")
          cliente.contribuinte = 9;
        else
          cliente.contribuinte = null;

        if (req.enderecoDestinatario.ie) cliente.ie = req.enderecoDestinatario.ie;
      }

      if (req.destinatario) {
        if (req.destinatario.foreignId) {
          cliente.nome_estrangeiro = req.destinatario.nome;
          cliente.nome_pais = req.enderecoDestinatario.paisName;
          cliente.cod_pais = req.enderecoDestinatario.pais;
        } else if (req.destinatario.cpfCnpj) {
          if (req.destinatario.cpfCnpj.length == 14) {
            cliente.cnpj = req.destinatario.cpfCnpj;
            cliente.razao_social = req.destinatario.nome;
          } else if (req.destinatario.cpfCnpj.length == 11) {
            cliente.cpf = req.destinatario.cpfCnpj;
            cliente.nome_completo = req.destinatario.nome;
          }
        }
      }

      cliente.consumidor_final = req.indFinal ? 1 : 0;
      json.cliente = cliente;


      /* ############################ Pedido ############################ */
      let pedido: any = {};
      let pagamento = [], forma_pagamento = [], valor_pagamento = [], desc_pagamento = [];
      if (req.pagamentoFormGroup) {
        req.pagamentoFormGroup.forEach(elem => {
          forma_pagamento.push(elem.MeioPagamento);
          valor_pagamento.push(elem.ValorPagamento ? elem.ValorPagamento.toString() : null);
          pagamento.push(elem.IndicadorFormaPagamento);
          desc_pagamento.push(elem.desc_pagamento);
        });

        pedido = {
          pagamento: pagamento,
          forma_pagamento: forma_pagamento,
          valor_pagamento: valor_pagamento,
          desc_pagamento: desc_pagamento,
          presenca: req.geral.indPres,
          modalidade_frete: req.transporte.modalidadeFrete,
          desconto: req.geral.valorTotalDesconto,
          frete: req.transporte.totalFrete,
          informacoes_complementares: req.informacoesComplementares.InfoCompl,
          informacoes_fisco: req.informacoesComplementares.InfoAoFisco,
        };
        json.pedido = pedido;
      }


      /* ############################ Geral ############################ */
      let dtEnt, dtEmi;
      if (req.geral) {
        dtEmi = moment(req.geral.dtEmi).format("YYYY-MM-DD HH:mm:ss");
        if (req.geral.dtEntExt) {
          dtEnt = moment(req.geral.dtEntExt).format("YYYY-MM-DD HH:mm:ss");
          json.data_entrada_saida = dtEnt;
        }
      }
      json.data_emissao = dtEmi;


      /* ############################ Produtos ############################ */
      let produtos = [];
      if (req.produtos) {
        console.log(req.produtos)
        req.produtos.forEach(p => {
          let produto: any = {
            quantidade: p.comercialQt.toString(),
            unidade: p.comercialUn,
            quantidade_tributavel: p.tributacaoQt,
            unidade_tributavel: p.tributacaoUn,
            gtin: p.gtin,
            ncm: p.ncm,
            subtotal: p.unCommerceValue.toString(),
            total: p.totalProdValue.toString(),

            beneficio_fiscal: p.BeneficioFiscal,
            impostos: {
              icms: {
                codigo_cfop: p.cfop,
                situacao_tributaria: p.SituacaoTributaria,
                aliquota: p.Aliquota,
                aliquota_diferimento: p.AliquotaDiferimento == "0" ? "0.00" : p.AliquotaDiferimento,
                aliquota_diferimento_fcp: p.AliquotaDiferimentoFCP == "0" ? "0.00" : p.AliquotaDiferimentoFCP,
                aliquota_reducao: p.AliquotaReducao == "0" ? "0.0000" : p.AliquotaReducao,
                bc_st_retido: p.bc_st_retido == "0" ? "0.00" : p.bc_st_retido,
                aliquota_st_retido: p.aliquota_st_retido == "0" ? "0.00" : p.aliquota_st_retido,
                valor_st_retido: p.valor_st_retido == "0" ? "0.00" : p.valor_st_retido,
                bc_st_dest: p.bc_st_dest == "0" ? "0.00" : p.bc_st_dest,
                valor_st_dest: p.valor_st_dest == "0" ? "0.00" : p.valor_st_dest,
                valor_fcp_retido: p.valor_fcp_retido == "0" ? "0.00" : p.valor_fcp_retido,
                aliquota_fcp_retido: p.aliquota_fcp_retido == "0" ? "0.00" : p.aliquota_fcp_retido,
                aliquota_bc_efetivo: p.aliquota_bc_efetivo == "0" ? "0.00" : p.aliquota_bc_efetivo,
                aliquota_icms_efetivo: p.aliquota_icms_efetivo == "0" ? "0.00" : p.aliquota_icms_efetivo,
                aliquota_reducao_st: p.aliquota_reducao_st == "0" ? "0.00" : p.aliquota_reducao_st,
                aliquota_mva: p.aliquota_mva == "0" ? "0.00" : p.aliquota_mva,
                industria: p.industria == "0" ? "0.00" : p.industria,
                aliquota_st: p.aliquota_st == "0" ? "0.00" : p.aliquota_st,
                aliquota_fcp_st: p.aliquota_fcp_st == "0" ? "0.00" : p.aliquota_fcp_st,
                aliquota_credito: p.aliquota_credito == "0" ? "0.00" : p.aliquota_credito,
                valor_icms_substituto: p.valor_icms_substituto == "0" ? "0.00" : p.valor_icms_substituto,
              },
              pis: {
                situacao_tributaria: p.SituacaoTributariaPis,
                aliquota: p.AliquotaPercentual
              },
              cofins: {
                situacao_tributaria: p.SituacaoTributariaCofins,
                aliquota: p.cofAliquotaPercentual
              },
              ipi: {
                situacao_tributaria: p.SituacaoTributariaIpi,
                codigo_enquadramento: p.codigo_enquadramento,
                aliquota: p.AliquotaIpi,
                ...(
                  json.finalidade === 4 ? {
                    ipi_devolvido: p.ipi_devolvido,
                    percentual_devolvido: p.percentual_devolvido
                  } : {}
                )
              }
            }
          };

          if (p.products) {
            produto.nome = p.products.description;
            produto.codigo = p.products.code;
            produto.origem = p.products.productOrigin.code;
          }

          produtos.push(produto);
        });
        json.produtos = produtos;
      }


      /* ############################ Faturas ############################ */
      let fatura: any = {}, parcelas = [];
      if (req.faturamento) {
        fatura = {
          numero: req.fatura.nFat,
          valor: req.fatura.vOrig.toString(),
          desconto: req.fatura.vDesc.toString(),
          valor_liquido: req.fatura.vLiq.toString(),
        };
        req.parcelas.forEach(element => {
          if (element.vDup) {
            parcelas.push({
              vencimento: element.dVenc,
              valor: element.vDup.toString()
            });
          }
        });

        json.fatura = fatura;
        json.parcelas = parcelas;
      }


      /* ############################ Transporte ############################ */
      let transporte: any = {};
      if (req.transporte) {
        if (req.transporte.modalidadeFrete != 9) {
          transporte = {
            volume: req.transporte.qtdVolumes.toString(),
            especie: req.transporte.especie,
            peso_bruto: req.transporte.pesoBruto.toString(),
            peso_liquido: req.transporte.pesoLiquido.toString(),
            endereco: req.transporte.endereco,
            marca: req.transporte.marca,
            lacres: req.transporte.numeroLacres,
            numeracao: req.transporte.numeracao
          };

          if (req.freteInfo) {
            if (req.freteInfo.cpfCNPJ.length == 11) {
              transporte.cpf = req.freteInfo.cpfCNPJ;
              transporte.nome_completo = req.freteInfo.name;
            } else {
              transporte.cnpj = req.freteInfo.cpfCNPJ;
              transporte.razao_social = req.freteInfo.name;
            }
            transporte.cidade = req.freteInfo.properties[0].cityName;
            transporte.cep = req.freteInfo.properties[0].cep;
            transporte.uf = req.freteInfo.properties[0].ufAbr;
            transporte.endereco = req.freteInfo.properties[0].street;
            transporte.ufId = req.freteInfo.properties[0].ufId;
            transporte.countryId = req.freteInfo.properties[0].countryId;
            transporte.bairro = req.freteInfo.properties[0].neighborhood;
            transporte.numero = req.freteInfo.properties[0].houseNumber;
            transporte.placa = req.freteInfo.frete[0].placaVeiculo;
            transporte.rntc = req.freteInfo.frete[0].registroNacional;
            transporte.seguro = req.freteInfo.frete[0].valorSeguro.toString();
            transporte.uf_veiculo = req.freteInfo.frete[0].ufVeiculoAbr;

            if (req.freteInfo.frete[0].reboque.length > 0) {
              transporte.reboque = [];
              req.freteInfo.frete[0].reboque.forEach((element) => {
                let obj = {
                  placa: element.placaVeiculo,
                  rntc: element.registroNacional,
                  vagao: element.identificacaoVagao,
                  balsa: element.identificacaoBalsa,
                  uf_veiculo: element.uf_veiculoAbr,
                };
                transporte.reboque.push(obj);
              });
            }
          }

          let enderecoRetirada: any = {};
          if (req.enderecoRetirada && !!Object.keys(req.enderecoRetirada).length) {
            enderecoRetirada = {
              uf: req.enderecoRetirada.properties[0].stateAbr,
              email: req.enderecoRetirada.email,
              cep: req.enderecoRetirada.properties[0].cep,
              endereco: req.enderecoRetirada.properties[0].street,
              numero: req.enderecoRetirada.properties[0].houseNumber,
              bairro: req.enderecoRetirada.properties[0].neighborhood,
              cidade: req.enderecoRetirada.properties[0].cityName,
              complemento: req.enderecoRetirada.properties[0].complement,
              substituto_tributario: req.enderecoRetirada.taxSubstitute
            };

            if (req.enderecoRetirada.cpfCNPJ){
              if (req.enderecoRetirada.cpfCNPJ.length == 14) {
                enderecoRetirada.cnpj = req.enderecoRetirada.cpfCNPJ;
                enderecoRetirada.razao_social = req.enderecoRetirada.name;
              } else if (req.enderecoRetirada.cpfCNPJ.length == 11) {
                enderecoRetirada.cpf = req.enderecoRetirada.cpfCNPJ;
                enderecoRetirada.nome_completo = req.enderecoRetirada.name;
              }
            }

            if (req.enderecoRetirada.properties) {
              if (req.enderecoRetirada.properties[0].ieType == 'ICMS_TAXPAYER')
                enderecoRetirada.contribuinte = 1;
              else if (req.enderecoRetirada.properties[0].ieType == 'EXEMPTED_TAXPAYER')
                enderecoRetirada.contribuinte = 2;
              else
                enderecoRetirada.contribuinte = 9;

              if (req.enderecoRetirada.properties[0].ie) enderecoRetirada.ie = req.enderecoRetirada.properties[0].ie;
            }
          } else {
            enderecoRetirada = null;
          }

          let enderecoRecebimento: any = {};
          if (req.enderecoRecebimento && !!Object.keys(req.enderecoRecebimento).length) {
            enderecoRecebimento = {
              uf: req.enderecoRecebimento.properties[0].stateAbr,
              email: req.enderecoRecebimento.email,
              cep: req.enderecoRecebimento.properties[0].cep,
              endereco: req.enderecoRecebimento.properties[0].street,
              numero: req.enderecoRecebimento.properties[0].houseNumber,
              bairro: req.enderecoRecebimento.properties[0].neighborhood,
              cidade: req.enderecoRecebimento.properties[0].cityName,
              telefone: req.enderecoRecebimento.telephone,
              complemento: req.enderecoRecebimento.properties[0].complement,
              substituto_tributario: req.enderecoRecebimento.taxSubstitute
            };


            if (req.enderecoRecebimento.foreignId) {
              enderecoRecebimento.nome_estrangeiro = req.enderecoRecebimento.name;
              enderecoRecebimento.nome_pais = req.enderecoRecebimento.properties[0].paisName;
              enderecoRecebimento.cod_pais = req.enderecoRecebimento.properties[0].pais;
            } else if (req.enderecoRecebimento.cpfCNPJ && req.enderecoRecebimento.cpfCNPJ.length == 14) {
              enderecoRecebimento.cnpj = req.enderecoRecebimento.cpfCNPJ;
              enderecoRecebimento.razao_social = req.enderecoRecebimento.name;
            } else if (req.enderecoRecebimento.cpfCNPJ && req.enderecoRecebimento.cpfCNPJ.length == 11) {
              enderecoRecebimento.cpf = req.enderecoRecebimento.cpfCNPJ;
              enderecoRecebimento.nome_completo = req.enderecoRecebimento.name;
            }

            if (req.enderecoRecebimento.properties[0].ieType == 'ICMS_TAXPAYER')
              enderecoRecebimento.contribuinte = 1;
            else if (req.enderecoRecebimento.properties[0].ieType == 'EXEMPTED_TAXPAYER')
              enderecoRecebimento.contribuinte = 2;
            else
              enderecoRecebimento.contribuinte = 9;

            if (req.enderecoRecebimento.properties[0].ie) enderecoRecebimento.ie = req.enderecoRecebimento.properties[0].ie;
          } else {
            enderecoRecebimento = null;
          }

          transporte.retirada = enderecoRetirada;
          transporte.entrega = enderecoRecebimento;
          json.transporte = transporte;
        }
      }


      /* ############################ Referenciada ############################ */
      let nfe_referenciada = [], ecf_referenciada = [], cte_referenciada = [], nf_rural_referenciada = [];
      if (req.chaves) {
        if (req.chaves[0].referenciada == 1) {
          req.chaves[1].forEach(element => {
            nfe_referenciada.push(element.chave);
          });
          json.nfe_referenciada = nfe_referenciada;
        } else if (req.chaves[0].referenciada == 2) {
          req.chaves[1].forEach(element => {
            cte_referenciada.push(element.chave);
          });
          json.cte_referenciada = cte_referenciada;
        } else if (req.chaves[0].referenciada == 3) {
          req.chaves[2].forEach(element => {
            ecf_referenciada.push({
              modelo: element.modelo,
              n_ecf: element.ecf,
              n_coo: element.coo
            });
          });
          json.ecf_referenciada = ecf_referenciada;
        } else if (req.chaves[0].referenciada == 4) {
          req.chaves[3].forEach(elem => {
            let obj = {
              uf: elem.estado,
              data: moment(elem.data).format("YYYY-MM-DD HH:mm:ss"),
              ie: elem.ie,
              modelo: elem.modelo,
              serie: elem.serie,
              numero: elem.numero
            };
            elem.cpfCnpj.length == 14 ? obj['cnpj'] = elem.cpfCnpj : obj['cpf'] = elem.cpfCnpj;
            nf_rural_referenciada.push(obj);
          });
          json.nf_rural_referenciada = nf_rural_referenciada;
        }
      }

      resolve(json);
    });
  }

  setJsonComplementar(emissor, enderecoEmissorSelecionado, enderecoDestinatarioSelecionado, destinatarioSelecionado, produtos, naturezaSelecionada, imposto, tipo,
                      chaveReferenciada, idEndereco, indFinal, preview, sixFormGroup, sendEmail, sendEmailClient) {
    return new Promise((resolve, reject) => {
try{
      let emissorObj
      //############################ Emissor ################,
      emissorObj = {
        sendEmail : sendEmail,
        sendEmailDest: sendEmailClient,
        platformId: 'APP_WEB'
      }
      let clienteObj: any = {
        uf: enderecoDestinatarioSelecionado.ufName,
        email: destinatarioSelecionado.email,
        cep: enderecoDestinatarioSelecionado.cep,
        endereco: enderecoDestinatarioSelecionado.logradouro,
        numero: Number(enderecoDestinatarioSelecionado.numero),
        bairro: enderecoDestinatarioSelecionado.bairro,
        cidade: enderecoDestinatarioSelecionado.municipioName,
        telefone: enderecoDestinatarioSelecionado.telefone,
        complemento: enderecoDestinatarioSelecionado.complemento
      };

      if (destinatarioSelecionado.foreignId) {
        clienteObj['nome_estrangeiro'] = destinatarioSelecionado.nome;
        clienteObj['nome_pais'] = enderecoDestinatarioSelecionado.paisName;
        clienteObj['cod_pais'] = enderecoDestinatarioSelecionado.pais;
      } else if (destinatarioSelecionado.cpfCnpj.length == 14) {
        clienteObj['cnpj'] = destinatarioSelecionado.cpfCnpj;
        clienteObj['razao_social'] = destinatarioSelecionado.nome;
      } else if (destinatarioSelecionado.cpfCnpj.length == 11) {
        clienteObj['cpf'] = destinatarioSelecionado.cpfCnpj;
        clienteObj['nome_completo'] = destinatarioSelecionado.nome;
      }
      if (enderecoDestinatarioSelecionado.ie) {
        clienteObj['ie'] = enderecoDestinatarioSelecionado.ie;
      }

      if (indFinal == false) {
        clienteObj['consumidor_final'] = 0;
      } else {
        clienteObj['consumidor_final'] = 1;
      }
      let json = {
        operacao: 1,
        natureza_operacao: naturezaSelecionada.descricao,
        ambiente: 2,
        emissor: emissorObj,
        previa_danfe: preview,
        nfe_referenciada: chaveReferenciada,
        cliente: clienteObj,
        informacoes_complementares: sixFormGroup.value.InfoCompl,
        informacoes_fisco: sixFormGroup.value.InfoAoFisco,
      };

      if (tipo != "imposto") {
        let produtosObj = [];
        produtos.forEach(element => {
          produtosObj.push({
            quantidade: element.comercialQt,
            unidade: element.comercialUn,
            quantidade_tributavel: element.tributacaoQt,
            unidade_tributavel: element.tributacaoUn,
            gtin: element.gtin,
            ncm: element.ncm,
            subtotal: element.unCommerceValue,
            nome: element.products.description,
            total: element.totalProdValue,
            codigo: element.products.code,
            origem: element.products.productOrigin.code,
            beneficio_fiscal: element.BeneficioFiscal,
            impostos: {
              icms: {
                codigo_cfop: element.cfop,
                situacao_tributaria: element.SituacaoTributaria,

              }
            }
          });
        });
        json['produtos'] = produtosObj;
      } else {
        let impostos = {
          codigo_cfop: imposto.codigo_cfop == "" ? null : imposto.codigo_cfop,
          situacao_tributaria: imposto.situacao_tributaria == "" ? null : imposto.situacao_tributaria,
          bc_icms: imposto.bc.toString() == "" ? null : imposto.bc.toString(),
          valor_icms: imposto.valor.toString() == "" ? null : imposto.valor.toString(),
          beneficio_fiscal: imposto.beneficio_fiscal == "" ? null : imposto.beneficio_fiscal,
          exigibilidade: imposto.exigibilidade == "" ? null : imposto.exigibilidade,
          item_servico: imposto.item_servico == "" ? null : imposto.item_servico,
          aliquota_mva: imposto.aliquota_mva == "" ? null : imposto.aliquota_mva,
          incentivo_fiscal: imposto.incentivo_fiscal == "" ? null : imposto.incentivo_fiscal,
        };
        json['impostos'] = impostos;
      }

      this.postNfeComplementar(json, enderecoEmissorSelecionado.id).then((result: any) => {
        if (result.status == "aprovado") {
          this.getPdfModalDanfe(result.key);
          Swal.fire({
            title: 'Sucesso!',
            text: 'Nota Fiscal foi emitida!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            }
          });
        } else if (result.status == "processamento") {
          Swal.fire({
            title: 'Sucesso!',
            text: 'Nota Fiscal está em processamento!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            }
          });
        } else if (result.status == "previa_danfe") {
          this.getPdfModalPrevia(result.danfe);
          resolve(true);
        } else {
          Swal.fire({
            title: 'Falha!',
            text: result.description,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            }
          });
        }

      }).catch(err => {
        console.error(err);
        Swal.fire({
          title: 'Falha!',
          text: err.error,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            resolve(true);
          }
        });
      });
} catch (err){
  reject(err);
}
    });
  }

  setJsonAjuste(enderecoEmissorSelecionado, enderecoDestinatarioSelecionado, destinatarioSelecionado, naturezaSelecionada, ajusteForm, preview, sixFormGroup, sendEmail, sendEmailClient) {
    return new Promise((resolve, reject) => {
      try {

        let emissorObj = {
          sendEmail: sendEmail,
          sendEmailDest: sendEmailClient,
          platformId: 'APP_WEB'
        };

        let clienteObj: any = {
          uf: enderecoDestinatarioSelecionado.ufName,
          email: destinatarioSelecionado.email,
          cep: enderecoDestinatarioSelecionado.cep,
          endereco: enderecoDestinatarioSelecionado.logradouro,
          numero: Number(enderecoDestinatarioSelecionado.numero),
          bairro: enderecoDestinatarioSelecionado.bairro,
          cidade: enderecoDestinatarioSelecionado.municipioName,
          telefone: enderecoDestinatarioSelecionado.telefone,
          complemento: enderecoDestinatarioSelecionado.complemento
        };

        if (destinatarioSelecionado.foreignId) {
          clienteObj['nome_estrangeiro'] = destinatarioSelecionado.nome;
          clienteObj['nome_pais'] = enderecoDestinatarioSelecionado.paisName;
          clienteObj['cod_pais'] = enderecoDestinatarioSelecionado.pais;
        } else if (destinatarioSelecionado.cpfCnpj.length == 14) {
          clienteObj['cnpj'] = destinatarioSelecionado.cpfCnpj;
          clienteObj['razao_social'] = destinatarioSelecionado.nome;
        } else if (destinatarioSelecionado.cpfCnpj.length == 11) {
          clienteObj['cpf'] = destinatarioSelecionado.cpfCnpj;
          clienteObj['nome_completo'] = destinatarioSelecionado.nome;
        }
        if (enderecoDestinatarioSelecionado.ie) {
          clienteObj['ie'] = enderecoDestinatarioSelecionado.ie;
        }

        let ajusteObj = ajusteForm.getRawValue();

        let json = {
          operacao: 1,
          natureza_operacao: naturezaSelecionada.descricao,
          ambiente: 2,
          emissor: emissorObj,
          previa_danfe: preview,
          cliente: clienteObj,
          informacoes_complementares: sixFormGroup.value.InfoCompl,
          informacoes_fisco: sixFormGroup.value.InfoAoFisco,
          codigo_cfop: ajusteObj.codigo_cfop,
          ...(ajusteObj.valor_icms ? {valor_icms: ajusteObj.valor_icms} : {}),
          ...(ajusteObj.valor_icms_st ? {valor_icms_st: ajusteObj.valor_icms_st} : {}),
          ...(ajusteObj.situacao_tributaria ? {situacao_tributaria: ajusteObj.situacao_tributaria} : {})
        };

        this.postNfeAjuste(json, enderecoEmissorSelecionado.id).then((result: any) => {
          if (result.status == 'aprovado') {
            this.getPdfModalDanfe(result.key);
            Swal.fire({
              title: 'Sucesso!',
              text: 'Nota Fiscal foi emitida!',
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                resolve(true);
              }
            });
          } else if (result.status == 'processamento') {
            Swal.fire({
              title: 'Sucesso!',
              text: 'Nota Fiscal está em processamento!',
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                resolve(true);
              }
            });
          } else if (result.status == 'previa_danfe') {
            this.getPdfModalPrevia(result.danfe);
            resolve(true);
          } else {
            Swal.fire({
              title: 'Falha!',
              text: result.description,
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                resolve(true);
              }
            });
          }
        }).catch(err => {
          console.error(err);
          Swal.fire({
            title: 'Falha!',
            text: err.error,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            }
          });
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getPdfModalPrevia(url) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => response.blob())
        .then(pdfBlob => {
          const pdfUrl = URL.createObjectURL(pdfBlob);
          const modalOptions: NgbModalOptions = {
            backdrop: 'static',
            windowClass: localStorage.getItem('theme') === 'dark' ? 'dark-theme custom-css-modal' : 'custom-css-modal'
          };
          const modalRef = this.modalService.open(PdfViewerComponent, modalOptions);
          modalRef.componentInstance.pdfUrl = pdfUrl;
          resolve();
        })
        .catch(reject);
    });
  }

  getPdfModalDanfe(key) {
    this.consultaPdfXml.getXml({chave: key})
      .then((resultXml : any) => this.consultaPdfXml.getPdfModal({chave: key, modelo: 'nfe', xml: resultXml.xml}))
      .catch(error => {
        console.error(error);
        this.snackBar.openLong('Não foi possível consultar o PDF/XML.', 'erro');
      });
  }

  setJsonComplementarDraft(req) {
    return new Promise((resolve, reject) => {

      /* ############################ JSON ############################ */
      let json: any = {
        previa_danfe: false,
        operacao: 1,
        ambiente: 1
      };

      if (req.natureza) {
        json.operacao = req.natureza.tipoNum;
        json.natureza_operacao = req.natureza.descricao;
        json.finalidade = req.natureza.finalidade == "NF-e normal" ? 1 : (req.natureza.finalidade == "NF-e complementar" ? 2 : 4);
      }

      if (req.chaveReferenciada) json.nfe_referenciada = req.chaveReferenciada;

      if (req.informacoesComplementares) {
        json.informacoes_complementares = req.informacoesComplementares.InfoCompl;
        json.informacoes_fisco = req.informacoesComplementares.InfoAoFisco;
      }

      /* ############################ Emissor ############################ */
      let emissor: any = {};
      if (req.emissor) emissor.issuerEmail = req.emissor[0].email;
      if (req.sendEmail) emissor.sendEmail = req.sendEmail;
      if (req.enderecoEmissor) {
        emissor.id = req.enderecoEmissor.id;
        emissor.ie = req.enderecoEmissor.inscricaoEstadual;
      }
      json.emissor = emissor;

      /* ############################ Cliente ############################ */
      let cliente: any = {};
      if (req.enderecoDestinatario) {
        cliente.uf = req.enderecoDestinatario.ufName;
        cliente.email = req.destinatario.email;
        cliente.cep = req.enderecoDestinatario.cep;
        cliente.endereco = req.enderecoDestinatario.logradouro;
        cliente.numero = req.enderecoDestinatario.numero;
        cliente.bairro = req.enderecoDestinatario.bairro;
        cliente.cidade = req.enderecoDestinatario.municipioName;
        cliente.telefone = req.enderecoDestinatario.telefone;
        cliente.complemento = req.enderecoDestinatario.complemento;
        cliente.substituto_tributario = req.destinatario.taxSubstitute;

        if (req.enderecoDestinatario.ieType == 'ICMS_TAXPAYER')
          cliente.contribuinte = 1;
        else if (req.enderecoDestinatario.ieType == 'EXEMPTED_TAXPAYER')
          cliente.contribuinte = 2;
        else if (req.enderecoDestinatario.ieType == "NON_ICMS_TAXPAYER")
          cliente.contribuinte = 9;
        else
          cliente.contribuinte = null;

        if (req.enderecoDestinatario.ie) cliente.ie = req.enderecoDestinatario.ie;
      }

      if (req.destinatario) {
        if (req.destinatario.foreignId) {
          cliente.nome_estrangeiro = req.destinatario.nome;
          cliente.nome_pais = req.enderecoDestinatario.paisName;
          cliente.cod_pais = req.enderecoDestinatario.pais;
        } else if (req.destinatario.cpfCnpj) {
          if (req.destinatario.cpfCnpj.length == 14) {
            cliente.cnpj = req.destinatario.cpfCnpj;
            cliente.razao_social = req.destinatario.nome;
          } else if (req.destinatario.cpfCnpj.length == 11) {
            cliente.cpf = req.destinatario.cpfCnpj;
            cliente.nome_completo = req.destinatario.nome;
          }
        }
      }

      cliente.consumidor_final = req.indFinal ? 1 : 0;
      json.cliente = cliente;

      /* ############################ Produtos ############################ */
      let produtos = [];
      console.log(req.tipo);
      if (req.tipo == "quantidade") {
        req.produtos.forEach(p => {
          produtos.push({
            quantidade: p.comercialQt,
            unidade: p.comercialUn,
            quantidade_tributavel: p.tributacaoQt,
            unidade_tributavel: p.tributacaoUn,
            gtin: p.gtin,
            ncm: p.ncm,
            subtotal: p.unCommerceValue,
            nome: p.products.description,
            total: p.totalProdValue,
            codigo: p.products.code,
            origem: p.products.productOrigin.code,
            beneficio_fiscal: p.BeneficioFiscal,
            impostos: {
              icms: {
                codigo_cfop: p.cfop,
                situacao_tributaria: p.SituacaoTributaria,
              }
            }
          });
        });

        json.produtos = produtos;
      } else if (req.tipo == "imposto") {
        let impostos = {
          codigo_cfop: req.impostos.codigo_cfop == "" ? null : req.impostos.codigo_cfop,
          situacao_tributaria: req.impostos.situacao_tributaria == "" ? null : req.impostos.situacao_tributaria,
          bc_icms: req.impostos.bc.toString() == "" ? null : req.impostos.bc.toString(),
          valor_icms: req.impostos.valor.toString() == "" ? null : req.impostos.valor.toString(),
          beneficio_fiscal: req.impostos.beneficio_fiscal == "" ? null : req.impostos.beneficio_fiscal,
          exigibilidade: req.impostos.exigibilidade == "" ? null : req.impostos.exigibilidade,
          item_servico: req.impostos.item_servico == "" ? null : req.impostos.item_servico,
          aliquota_mva: req.impostos.aliquota_mva == "" ? null : req.impostos.aliquota_mva,
          incentivo_fiscal: req.impostos.incentivo_fiscal == "" ? null : req.impostos.incentivo_fiscal,
        };
        json.tipoImposto = req.tipoImposto;
        json.impostos = impostos;
      }

      resolve(json);
    });
  }

  setJsonAjusteDraft(req) {
    return new Promise((resolve, reject) => {

      let json: any = {
        previa_danfe: false,
        operacao: 1,
        ambiente: 1,
        ...(req.ajuste.codigo_cfop ? {codigo_cfop: req.ajuste.codigo_cfop} : {}),
        ...(req.ajuste.valor_icms ? {valor_icms: req.ajuste.valor_icms} : {}),
        ...(req.ajuste.valor_icms_st ? {valor_icms_st: req.ajuste.valor_icms_st} : {}),
        ...(req.ajuste.situacao_tributaria ? {situacao_tributaria: req.ajuste.situacao_tributaria} : {}),
      };

      /* ############################ Natureza ############################ */
      if (req.natureza) {
        json.operacao = req.natureza.tipoNum;
        json.natureza_operacao = req.natureza.descricao;
        json.finalidade = 3;
      }

      /* ############################ Infos Complementares ############################ */
      if (req.informacoesComplementares) {
        json.informacoes_complementares = req.informacoesComplementares.InfoCompl;
        json.informacoes_fisco = req.informacoesComplementares.InfoAoFisco;
      }

      /* ############################ Emissor ############################ */
      let emissor: any = {};
      if (req.emissor) emissor.issuerEmail = req.emissor[0].email;
      if (req.sendEmail) emissor.sendEmail = req.sendEmail;
      if (req.enderecoEmissor) {
        emissor.id = req.enderecoEmissor.id;
        emissor.ie = req.enderecoEmissor.inscricaoEstadual;
      }
      json.emissor = emissor;

      /* ############################ Cliente ############################ */
      let cliente: any = {};
      if (req.enderecoDestinatario) {
        cliente.uf = req.enderecoDestinatario.ufName;
        cliente.email = req.destinatario.email;
        cliente.cep = req.enderecoDestinatario.cep;
        cliente.endereco = req.enderecoDestinatario.logradouro;
        cliente.numero = req.enderecoDestinatario.numero;
        cliente.bairro = req.enderecoDestinatario.bairro;
        cliente.cidade = req.enderecoDestinatario.municipioName;
        cliente.telefone = req.enderecoDestinatario.telefone;
        cliente.complemento = req.enderecoDestinatario.complemento;
        cliente.substituto_tributario = req.destinatario.taxSubstitute;

        if (req.enderecoDestinatario.ieType == 'ICMS_TAXPAYER')
          cliente.contribuinte = 1;
        else if (req.enderecoDestinatario.ieType == 'EXEMPTED_TAXPAYER')
          cliente.contribuinte = 2;
        else if (req.enderecoDestinatario.ieType == "NON_ICMS_TAXPAYER")
          cliente.contribuinte = 9;
        else
          cliente.contribuinte = null;

        if (req.enderecoDestinatario.ie) cliente.ie = req.enderecoDestinatario.ie;
      }

      if (req.destinatario) {
        if (req.destinatario.foreignId) {
          cliente.nome_estrangeiro = req.destinatario.nome;
          cliente.nome_pais = req.enderecoDestinatario.paisName;
          cliente.cod_pais = req.enderecoDestinatario.pais;
        } else if (req.destinatario.cpfCnpj) {
          if (req.destinatario.cpfCnpj.length == 14) {
            cliente.cnpj = req.destinatario.cpfCnpj;
            cliente.razao_social = req.destinatario.nome;
          } else if (req.destinatario.cpfCnpj.length == 11) {
            cliente.cpf = req.destinatario.cpfCnpj;
            cliente.nome_completo = req.destinatario.nome;
          }
        }
      }

      json.cliente = cliente;


      resolve(json);
    });
  }


  postNfe(json, idEndereco) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + '/invoice-issuer/v1/nfe/issue/' + localStorage.getItem('userId') + "/" + idEndereco, json, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          }, (error) => {
            reject(error);
          });

    });
  }

  postNfeComplementar(json, idEndereco) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + '/invoice-issuer/v1/nfe/complementary/' + localStorage.getItem('userId') + "/" + idEndereco, json, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          }, (error) => {
            reject(error);
          });

    });
  }

  postDevolutionNfe(json) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + '/invoice-issuer/v1/nfe/' + localStorage.getItem("userId") + "/" + "devolution", json, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          }, (error) => {
            reject(error);
          });
    });
  }

  postNfeAjuste(json, idEndereco) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + '/invoice-issuer/v1/nfe/nfe-adjust/' + localStorage.getItem('userId') + '/' + idEndereco, json, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });

    });
  }

}
