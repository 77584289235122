import {Injectable} from '@angular/core';

@Injectable()
export class MenuService {

  menuLixo: Array<any>;
  menuLateral: any[];

  constructor() {
    this.menuLixo = [];
    this.menuLateral = [];
  }

  addMenu(items: Array<{
    text: string,
    heading?: boolean,
    link?: string,     // internal route links
    elink?: string,    // used only for external links
    target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
    parentAsTarget?: boolean,
    icon?: string,
    alert?: string,
    submenu?: Array<any>
  }>) {
    this.menuLixo = [];
    this.menuLateral = [];
    items.forEach((item) => {
      this.menuLateral.push(item);
    });
  }

  getMenu() {
    return this.menuLixo;
  }

  getMenuLateral() {
    return this.menuLateral;
  }

}
