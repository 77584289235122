import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {LayoutModule} from './layout/layout.module';
import {SharedModule} from './shared/shared.module';
import {RoutesModule} from './routes/routes.module';
import {PdfViewerComponent} from './routes/notafiscal/pdf-viewer/pdf-viewer.component';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatOptionModule,
  ShowOnDirtyErrorStateMatcher
} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import {MomentUtcDateAdapter} from '../util/MomentUtcDateAdapter';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import {NgxCurrencyModule} from 'ngx-currency';
import {MatSortModule} from '@angular/material/sort';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {DatePicker} from '../util/datePicker';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {AuthGuardService} from '../service/auth-guard';
import {AuthGuardNotaFiscalService} from '../service/auth-guard-notafiscal';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMenuModule} from '@angular/material/menu';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {Snackbar} from '../util/snackbar';
import {AuthGuardContadorFuncionarioService, AuthGuardContadorService} from '../service/auth-contador';
import {BrMaskerIonicServices3} from 'brmasker-ionic-3';
import {UnitProvider} from 'src/providers/produto/unidade';
import {ProductProvider} from 'src/providers/nfe/product';
import {CertificadoProvider} from '../providers/certificado/certificadoProvider';
import {EstadosMunicipiosUtil} from '../util/estados-municipios';
import {Banks} from '../util/banks';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {CountriesProvider} from '../providers/basicos/countries';
import {CitiesProvider} from '../providers/basicos/cities';
import {StatesProvider} from '../providers/basicos/states';
import {BanksProvider} from '../providers/basicos/banks';
import {BankAccountProvider} from '../providers/company/bank_account/bank_account';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {PropertyProvider} from '../providers/company/property/property';
import {nfeJsonProvider} from '../providers/nfe/nfeJson';
import {MemberProvider} from '../providers/company/member/member';
import {ErrorUtil} from '../util/error';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {PaginationConfig, PaginationModule} from 'ngx-bootstrap/pagination';
import {LivroCaixa} from '../util/livroCaixa';
import {MatListModule} from '@angular/material/list';
import {ExcelService} from '../service/excel.service';
import {getPtPaginatorIntl} from '../util/paginatorConfig';
import {IMaskModule} from 'angular-imask';
import {DfeMapper} from '../model/dto/mapper/dfeMapper';
import {PessoaMapper} from '../model/dto/mapper/pessoaMapper';
import {UserProvider} from '../providers/user/user';
import {ParticipantProvider} from '../providers/participant/participant';
import {CompanyProvider} from '../providers/company/company/company';
import {DuplicatesMapper} from '../model/dto/mapper/duplicatesMapper';
import {LoginModalComponent} from './routes/pages/login-modal/login-modal.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {TreeModule} from 'angular-tree-component';
import {HighlightPipe} from '../util/pipes/highlightPipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';
import {CheckCNPJProvider} from '../providers/basicos/checkCNPJ';
import {ExplorationProvider} from '../providers/company/exploration/exploration';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {registerLocaleData} from '@angular/common';
import {ReceiverProvider} from '../providers/nfe/receiver';
import localePt from '@angular/common/locales/pt';
import {DocumentsBookkepingMapper} from '../model/dto/mapper/documentsBookkepingMapper';
import {ExcelExportService, FreezeService, PdfExportService, TreeGridModule} from '@syncfusion/ej2-angular-treegrid';
import {AggregateService, ColumnChooserService, GridAllModule, GridModule, GroupService, ResizeService} from '@syncfusion/ej2-angular-grids';
import {DropDownListAllModule} from '@syncfusion/ej2-angular-dropdowns';
import {MatSelectFilterModule} from 'mat-select-filter';
import {TextMaskModule} from 'angular2-text-mask';
import {RouterModule} from '@angular/router';
import {routes} from './routes/routes';
import {UpdateService} from '../service/updateService';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatToolbarModule} from '@angular/material/toolbar';
import {HeaderComponent} from './layout/header/header.component';
import {BasicAlertComponent} from './shared/alert/basic-alert/basic-alert.component';
import {BackupProvider} from '../providers/backup/backup';
import {DateUtil} from '../util/dateUtil';
import {SuperUserProvider} from '../providers/user/superUser';
import {CheckPermission} from '../service/checkPermission';
import {MatStepperModule} from '@angular/material/stepper';
import {DialogModule} from '@syncfusion/ej2-angular-popups';
import {ConvertObjectPipe} from '../util/pipes/ConvertObjectPipe';
import {PagesModule} from './routes/pages/pages.module';
import {PipesModule} from '../util/pipes/pipes.module';
import {DatePickerModule, DateRangePickerModule} from '@syncfusion/ej2-angular-calendars';
import {MatExpansionModule} from '@angular/material/expansion';
import {ConvertImovelDTO} from '../util/pipes/ConvertImovelDTO';
import {OnlyNumberDirective} from './shared/directives/onlyNumber/onlyNumberDirective';
import {RollbarErrorHandler, rollbarFactory, RollbarService} from '../service/rollbar';
import {SupportModalComponent} from './layout/header/support-modal/support-modal.component';
import {LayoutNotaFiscalModule} from './layoutNotaFiscal/layout.module';
import {NotaFiscalModule} from './routes/notafiscal/notafiscal.module';
import {NgxCaptchaModule} from 'ngx-captcha';
import {MatRadioModule} from '@angular/material/radio';
import {OpnatureProvider} from 'src/providers/nfe/opnature';
import {ImpostoProvider} from 'src/providers/nfe/imposto';
import {InfosAdicionaisProvider} from 'src/providers/nfe/infosAdicionais';
import {GroupByPipe} from 'src/util/pipes/GroupByPipe';
import {GroupByPipeMonth} from 'src/util/pipes/GroupByMonth';
import {EventsProvider} from 'src/providers/events/events';
import {ForgotPasswordComponent} from './routes/pages/forgot-password/forgot-password.component';
import {RascunhosProvider} from '../providers/nfe/rascunhos';
import {MaintenanceProvider} from 'src/providers/status/maintenanceProvider';
import {SharedService} from "../service/shared";
import {ConsultaPdfXml} from 'src/providers/nfe/consultaPdfXml';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localePt, 'pt-BR');


@NgModule({
  declarations: [
    AppComponent,
    BasicAlertComponent,
    LoginModalComponent,
    OnlyNumberDirective,
    HighlightPipe,
    ConvertObjectPipe,
    ConvertImovelDTO,
    BasicAlertComponent,
    PdfViewerComponent,
    ForgotPasswordComponent,
    GroupByPipe,
    GroupByPipeMonth
  ],
  imports: [

    GridAllModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    DialogModule,
    PipesModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    IMaskModule,
    LayoutModule,
    LayoutNotaFiscalModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    PaginationModule,
    MatSortModule,
    MatSelectFilterModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatCardModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatToolbarModule,
    MatTableModule,
    NgxMatSelectSearchModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDialogModule,
    MatButtonModule,
    MatAutocompleteModule,
    environment.production ? ServiceWorkerModule.register('/ngsw-worker.js') : [],
    MatListModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    NgxDaterangepickerMd.forRoot({
      separator: ' - ',
      format: 'DD/MM/YYYY',
      direction: 'ltr',
      customRangeLabel: 'Personalizado',
      applyLabel: 'Aplicar'
    }),
    OAuthModule.forRoot(),
    RoutesModule,
    RouterModule.forRoot(
      routes,
      {enableTracing: false} // <-- debugging purposes only
    ),
    GridModule,
    TreeModule.forRoot(),
    ReactiveFormsModule,
    SharedModule.forRoot(),
    TreeGridModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MatProgressSpinnerModule,
    MatProgressBarModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {enabled: environment.production}),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatTooltipModule,
    MatChipsModule,
    DropDownListAllModule,
    TextMaskModule,
    MatStepperModule,
    DragDropModule,
    PagesModule,
    DateRangePickerModule,
    DatePickerModule,
    MatExpansionModule,
    NotaFiscalModule,
    NgxSkeletonLoaderModule,
    NgxCaptchaModule,
    MatRadioModule
  ],
  providers: [
    AggregateService,
    AuthGuardService,
    AuthGuardNotaFiscalService,
    AuthGuardContadorService,
    AuthGuardContadorService,
    AuthGuardContadorService,
    AuthGuardContadorFuncionarioService,
    Banks,
    PaginationConfig,
    BackupProvider,
    BankAccountProvider,
    BanksProvider,
    ReceiverProvider,
    UnitProvider,
    ProductProvider,
    EventsProvider,
    BrMaskerIonicServices3,
    CertificadoProvider,
    CitiesProvider,
    CheckCNPJProvider,
    CompanyProvider,
    CountriesProvider,
    OpnatureProvider,
    ImpostoProvider,
    InfosAdicionaisProvider,
    ConsultaPdfXml,
    RascunhosProvider,
    ColumnChooserService,
    DatePicker,
    DateUtil,
    DocumentsBookkepingMapper,
    DfeMapper,
    DuplicatesMapper,
    ErrorUtil,
    EstadosMunicipiosUtil,
    ExcelService,
    ExcelExportService,
    ExplorationProvider,
    GroupService,
    LivroCaixa,
    MemberProvider,
    NgxSkeletonLoaderModule,
    ParticipantProvider,
    PessoaMapper,
    PropertyProvider,
    nfeJsonProvider,
    CheckPermission,
    SharedService,
    StatesProvider,
    Snackbar,
    HeaderComponent,
    UserProvider,
    UpdateService,
    ResizeService,
    FreezeService,
    PdfExportService,
    SuperUserProvider,
    {provide: OAuthStorage, useValue: localStorage},
    {provide: MatPaginatorIntl, useValue: getPtPaginatorIntl()},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {provide: LOCALE_ID, useValue: 'pt-BR'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
    {provide: DateAdapter, useClass: MomentUtcDateAdapter},
    {provide: ErrorHandler, useClass: RollbarErrorHandler},
    {provide: RollbarService, useFactory: rollbarFactory},
    MaintenanceProvider
  ],
  entryComponents: [
    BasicAlertComponent,
    LoginModalComponent,
    SupportModalComponent,
    ForgotPasswordComponent,
    PdfViewerComponent
  ],
  exports: [],
  bootstrap: [AppComponent]
})

export class AppModule {
}
