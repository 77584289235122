import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {Inject, Injectable} from '@angular/core';
import {LoginModalComponent} from '../app/routes/pages/login-modal/login-modal.component';
import {RollbarService} from '../service/rollbar';
import * as Rollbar from 'rollbar';
import {environment} from '../environments/environment';

@Injectable()
export class ErrorUtil {
  erro401 = false;

  constructor(public modalService: NgbModal, @Inject(RollbarService) private rollbar: Rollbar) {

  }

  checkErrorStatus(result, erro: number, error, place, object?) {
    const claims = (JSON.parse(localStorage.getItem("id_token_claims_obj")));
    this.rollbar.configure({
      payload: {
        person: {
          id: claims.principal.id,
          version: environment.appVersion,
          office: claims.principal.office.id +  ' ' + claims.principal.office.name,
          company: localStorage.idEmpresa +  '-' + localStorage.empresa,
          username: '(' + claims.principal.office.id +  ' ' + claims.principal.office.name + ')'  +  ' ' + localStorage.idEmpresa +  '-' + localStorage.empresa,
          email: claims.principal.username,
          object: object
        }
      }
    });
    if (erro == undefined || error == undefined) {
      this.rollbar.error(place + ' - ' + result.message);
      return result.message;
    } else {
      this.rollbar.error(place + ' - ' + error.error);
      if (error.error === 'bad_certificate_password') {
        return erro + ' - Senha do certificado incorreta!';
      } else if (error.error === 'certificate_not_valid') {
        return erro + ' - Certificado inválido!';
      } else if (error.error === 'some_external_dependence') {
        return erro + ' - Esse elemento não pode ser excluído devido a dependências existentes. Confira suas inserções e tente novamente!';
      } else if (error.error === 'key_already_exist') {
        if (place === 'property') {
          return erro + ' - O código inserido já está atrelado a outro imóvel!';
        } else if (place === 'certificate') {
          return erro + ' - O certificado já existe!';
        } else if (place === 'member') {
          return erro + ' - CNPJ/CPF já cadastrado!';
        } else if (place === 'bankAccount') {
          return erro + ' - O código inserido já está atrelado a outra conta movimento!';
        } else {
          return erro + ' - O código ou índice já existe!';
        }
      } else if (error.error === 'blocked_participant') {
        return erro + ' - O participantes não pode ser removido! Ele provavalmente está vinculado a um documento fiscal.';
      } else if (error.error === 'Too many backups') {
        return 'O limite diário de geração de backup foi excedido. Ele é permitido duas vezes ao dia.';
      } else if (error.error === 'cpf_cnpj_not_valid') {
        return erro + ' - CPF/CNPJ inválido!';
      } else if (error.error === 'access_denied') {
        if (place === 'password') {
          return erro + ' - Senha atual incorreta!';
        } else {
          return erro + ' - Acesso negado!';
        }
      } else if (error.error === 'internal_server_error') {
        if (place === 'participant') {
          return erro + ' - Nome ou CPF/CNPJ possivelmente já cadastrado!';
        } else {
          return erro + ' - Erro interno do servidor!';
        }
      } else if (error.error === 'password_not_valid') {
        return erro + ' - Senha inválida!';
      } else if (error.error === 'resource_not_found') {
        if (place === 'documents') {
          return erro + ' - Recurso não encontrado! Verifique se os produtores cadastrados pertencem a DFe!';
        } else {
          return erro + ' - Recurso não encontrado!';
        }
      } else if (error.error === 'invalid_dfe_key') {
        return erro + ' - A chave da DFe é  inválida!';
      } else if (error.error === 'invalid_dfe_type') {
        return erro + ' - O tipo da DFe é inválido!';
      } else if (error.error === 'impossible_parse_xml') {
        return erro + ' - Não foi possível ler o XML!';
      } else if (error.error === 'invalid_request_param') {
        if (place === 'historic') {
          return erro + ' - O código inserido já está atrelado a outro histórico!';
        } else {
          return erro + ' - Parâmetro inválido!';
        }
      } else if (erro === 401 && !this.erro401) {
        this.erro401 = true;
        setTimeout(() => {
          let modalOptions: NgbModalOptions = {
            backdrop: 'static',
            windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
          };
          this.modalService.open(LoginModalComponent, modalOptions);
        }, 3000);
      } else {
        return this.checkError(erro);
      }
    }
  }

  checkError(erro: number) {
    if (erro === 401 && !this.erro401) {
      this.erro401 = true;
      setTimeout(() => {
        let modalOptions: NgbModalOptions = {
          backdrop: 'static',
          windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
        };
        this.modalService.open(LoginModalComponent, modalOptions);
      }, 3000);
    }

    return this.returnCodeErrorMessage(erro);
  }

  returnCodeErrorMessage(erro) {
    switch (erro) {
      case 401: {
        return '401 - Você não tem autorização. Tente refazer o login!';
      }
      case 404: {
        return ' 404 - Problema ao comunicar-se com o servidor.';
      }
      case 500: {
        return '500 - Erro interno no servidor. Contate o suporte se persistir.';
      }
      case 422: {
        return '422 - Não foi possível inserir. Verifique se o código inserido já não existe.';
      }
      default: {
        return erro + ' - Erro desconhecido. Verifique sua conexão.';
      }
    }
  }
}
