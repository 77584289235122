/* tslint:disable */
import {Component, Inject, OnInit} from '@angular/core';
import {SettingsService} from '../../../core/settings/settings.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'ng2-validation';
import {OAuthService} from 'angular-oauth2-oidc';
import {authPasswordFlowConfig} from '../../../../service/auth-flow-config';
import {Router} from '@angular/router';
import {Snackbar} from '../../../../util/snackbar';
import {menuNotaFiscal, menuNotaFiscalContador} from '../../menu';
import {MenuService} from '../../../core/menu/menu.service';
import {UserProvider} from '../../../../providers/user/user';
import * as moment from 'moment';
import {EstadosMunicipiosUtil} from '../../../../util/estados-municipios';
import {Banks} from '../../../../util/banks';
import {CheckPermission} from '../../../../service/checkPermission';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {RollbarService} from '../../../../service/rollbar';
import * as Rollbar from 'rollbar';
import {environment} from '../../../../environments/environment';
import Swal from 'sweetalert2';
import {ForgotPasswordComponent} from '../forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  valForm: FormGroup;
  private password: string;
  private loginFailed: boolean;
  logando = false;
  public captchaSolved = false;
  public siteKey: string;
  public isGerensia = false;

  constructor(public settings: SettingsService,
              fb: FormBuilder,
              private snackBar: Snackbar,
              private menuService: MenuService,
              private modalService: NgbModal,
              private userProvider: UserProvider,
              private checkPermissionUser: CheckPermission,
              private estadosMunicipiosUtil: EstadosMunicipiosUtil,
              @Inject(RollbarService) private rollbar: Rollbar,
              private banksUtil: Banks,
              private router: Router,
              private oauthService: OAuthService) {
    // document.cookie = `access_token=18364205-9591-4265-bd9e-5d8118169dd6;path=/;domain=.farmcont.com`
    //console.log("testeeeeeeeeeeeeee",getCookie('access_token'))
    if (getCookie('access_token')) {
      userProvider.getInfoLogin(getCookie('access_token')).then(() => {
        //localStorage.setItem('userId', getCookie('produtor_Id'))
        this.setTrackerInfo();
        this.setBasicData();
        //this.checkPermission();
        this.router.navigate(['notafiscal/contador']);
        //this.router.navigate(['notafiscal']);
      });
    }
    this.siteKey = '6LfHZ24aAAAAAEIwVK8cNzX2MEV7Zy19LvtQAV3p';
    this.valForm = fb.group({
      'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
      'password': [null, Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.alreadyAuthenticated();
    if (window.location.href.includes('gerensia')) {
      this.isGerensia = true;
    }
  }

  checkAuth() {
    if (!["2906", "2721", "1718", "1802", "2"].includes(localStorage.getItem('idEmpresa'))) {
      Swal.fire({
        title: "Você não tem permissão para acessar o emissor!",
        text: "Entre em contato com o suporte para obter acesso ao emissor.",
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        allowEscapeKey: false
      });
      return false;
    } else {
      return true;
    }
  }

  openSnackBar(mensagem: string, tipo: string) {
    this.snackBar.open(mensagem, tipo);
  }

  setTrackerInfo() {
    //console.log'2');
    //console.log("",localStorage.getItem("id_token_claims_obj"))
    const claims = (JSON.parse(localStorage.getItem("id_token_claims_obj")));
    this.rollbar.configure({
      payload: {
        person: {
          id: claims.principal.id,
          version: environment.appVersion,
          office: claims.principal.office.id + ' ' + claims.principal.office.name,
          company: localStorage.idEmpresa + '-' + localStorage.empresa,
          username: '(' + claims.principal.office.id + ' ' + claims.principal.office.name + ')' + ' ' + localStorage.idEmpresa + '-' + localStorage.empresa,
          email: claims.principal.username
        }
      }
    });
  }

  private loginWithPassword(user: string, password: string) {
    //console.log("leeee");
    if (true) {
      //console.log'0');
      this.oauthService.configure(authPasswordFlowConfig);
      this.oauthService
        .fetchTokenUsingPasswordFlowAndLoadUserProfile(user, password).then((resp: LoginResponse) => {
        this.openSnackBar("Usuário logado com sucesso!", "success");
        if (resp.details) {
          localStorage.setItem('idEmpresa', `${resp.details.companyIds[0]}`);
          localStorage.setItem('userId', `${resp.details.userId}`);
          localStorage.setItem('userIdOriginal', `${resp.details.userId}`);
        }
        this.checkPermission();
        this.setTrackerInfo();
        this.setBasicData();
      }).catch(err => {
        if (err.status == 400) {
          this.openSnackBar("Usuário ou senha incorretos", "erro");
          this.logando = false;
        } else {
          this.openSnackBar("Não foi possível conectar", "erro");
          this.logando = false;
        }
        this.loginFailed = true;
      });
    }
  }

  private setBasicData() {
    //console.log'3');
    this.estadosMunicipiosUtil.setCountry();
    this.banksUtil.setBanks();
  }

  private alreadyAuthenticated() {

    if (this.oauthService.hasValidAccessToken()) {
      this.checkPermission();
    }
  }

  private checkPermission() {
    localStorage.setItem('workDate', (moment().format('YYYY-MM-DD')));
    this.menuService.menuLateral = [];
    if (this.checkPermissionUser.isSuperuser()) {
      if (localStorage.getItem('userIdOriginal') != localStorage.getItem('userId')) {
        this.menuService.addMenu(menuNotaFiscalContador);
      }
      this.router.navigate(['notafiscal/contador']);
    } else {
      if (this.checkPermissionUser.isAccountant()) {
        if (localStorage.getItem('userIdOriginal') != localStorage.getItem('userId')) {
          this.menuService.addMenu(menuNotaFiscalContador);
        }
        this.router.navigate(['notafiscal/contador']);
      } else if (this.checkPermissionUser.isFarmer()) {
        this.menuService.addMenu(menuNotaFiscal);
        try {
          this.router.navigate(['notafiscal']);
        } catch (exception) {
          console.error('Erro ao acessar o painel do produtor!', exception);
        }
      } else {
        this.logout();
      }
    }
  }

  submitForm($ev, value: any) {
    if (this.captchaSolved) {
      this.logando = true;
      $ev.preventDefault();
      for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
      }
      if (this.valForm.valid) {
        this.loginWithPassword(value.email, value.password);
      } else {
        this.logando = false;
      }
    } else {
      this.snackBar.open('Resolva o captcha antes de fazer login', 'erro');
    }
  }

  private logout() {
    document.cookie = `access_token=;path=/;domain=.farmcont.com.br`;

    this.oauthService.logOut(true);
    this.router.navigate(["login"]);
    setTimeout(() => {
      this.snackBar.open('Usuário não possui permissão acesso!', 'erro');
    });
  }

  public forgotPassword() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(ForgotPasswordComponent, modalOptions);
    modalRef.result.then((result) => {
      console.log(result);
    });
  }

  handleSuccess() {
    this.captchaSolved = true;
  }

}

class LoginResponse {
  authenticated: boolean;
  authorities: [{ authority: string }];
  details: {
    accountNonExpired: boolean,
    accountNonLocked: boolean,
    authorities: [{ authority: string }],
    collaboratorIds: number[],
    companyIds: number[],
    credentialsNonExpired: boolean,
    enabled: boolean,
    id: number,
    userId: any,
    lastName: string,
    memberId?: number,
    memberUserIds: number[],
    name: string,
    notAfter?: any,
    notBefore?: any,
    office: {
      id: number,
      name: string
    },
    userName: string,
  };
  oauth2Request: {
    approved: boolean,
    authorities: [],
    clientId: string,
    extensions: {},
    grantType: string,
    redirectUri: any,
    refresh: boolean,
    refreshTokenRequest: any,
    requestParameters: {
      grant_type: string,
      scope: string,
      username: string,
    },
    resourceIds: string[],
    responseTypes: [],
    scope: string[],
  };
  principal: {
    accountNonExpired: boolean,
    accountNonLocked: boolean,
    authorities: [{ authority: string }],
    collaboratorIds: number[],
    companyIds: number[],
    credentialsNonExpired: boolean,
    enabled: boolean,
    id: number,
    lastName: string,
    memberId: any,
    memberUserIds: [],
    name: string,
    notAfter: any,
    notBefore: any,
    office: { id: number, name: string },
    userName: string,
  };
  userAuthentication: {
    authenticated: boolean,
    authorities: [{ authority: string }],
    credentials: any,
    details: {
      grant_type: string,
      scope: string,
      username: string,
    },
    name: string,
    principal: {
      accountNonExpired: boolean,
      accountNonLocked: boolean,
      authorities: [{ authority: string }],
      collaboratorIds: number[],
      companyIds: number[],
      credentialsNonExpired: boolean,
      enabled: boolean,
      id: number,
      lastName: string,
      memberId: any,
      memberUserIds: [],
      name: string,
      notAfter: any,
      notBefore: any,
      office: { id: number, name: string },
      userName: string,
    }
  };
}

function getCookie(cname) {
  let name = cname + "=";
  console.log("cukie", document.cookie);
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
