import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
// `import Rollbar from 'rollbar';` is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.
import {ErrorHandler, Inject, Injectable, InjectionToken} from '@angular/core';
import {environment} from "../environments/environment";

const rollbarConfig = {
  accessToken: '0998c9dcc31044988f5abb6568a4fee5',
  captureUncaught: true,
  captureUnhandledRejections: true,
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err:any) : void {
    const claims = (JSON.parse(localStorage.getItem("id_token_claims_obj")));
    this.rollbar.configure({
      payload: {
        person: {
          id: claims.principal.id,
          version: environment.appVersion,
          office: claims.principal.office.id +  ' ' + claims.principal.office.name,
          company: localStorage.idEmpresa +  '-' + localStorage.empresa,
          username: '(' + claims.principal.office.id +  ' ' + claims.principal.office.name + ')'  +  ' ' + localStorage.idEmpresa +  '-' + localStorage.empresa,
          email: claims.principal.username
        }
      }
    });
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
