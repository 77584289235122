import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import jsPDF from 'jspdf';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
//import {MatDialog} from "@angular/material/dialog";
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {CadastroProdutoComponent} from './cadastro-produto/cadastro-produto.component';
import {ProductProvider} from 'src/providers/nfe/product';
import Swal from 'sweetalert2';
import {CheckPermission} from 'src/service/checkPermission';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss']
})

export class ProdutosComponent implements OnInit {
  public carregando: Boolean = true;
  displayedColumns = ['editar', 'excluir', 'isShared', 'code', 'description', 'amount',  'ncmcode'];
  public formGroup: FormGroup;
  public unidades: Unidade[] = [];
  public produtos: Produto[] = [];
  private product: any[];

  //private unidade

  dataSource: MatTableDataSource<Produto>;
  erro: boolean = false;
  erroDescription: boolean = false;
  consulta: boolean = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('trigger', {static: false}) trigger: MatAutocompleteTrigger;


  constructor(private formBuilder: FormBuilder,
              public modalService: NgbModal,
              public productProvider: ProductProvider,
              public checkPermission: CheckPermission) {
  }

  ngOnInit() {
    try {
     //console.log"storage:",localStorage.getItem('idEmpresa'),"hahah: ",localStorage.getItem('empresa'))
      this.generateTable();

    } catch (exception) {
      console.error(exception);
    }
  }


  loadTable(){
    this.dataSource = new MatTableDataSource<Produto>(this.produtos);
      this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }


    generateTable(){
      this.carregando = true;
      this.produtos = [];
      this.getProdutos().then(() =>{
      this.loadTable();
      //console.log(this.produtos)
      this.carregando = false;
      });
    }

  getProdutos() {
    return new Promise((resolve, reject) => {
      this.productProvider.getAllProducts().then((result) => {
        //console.log"testeteste", result);
        Object.keys(result).forEach((item) => {
          //console.log"teste2teste2", result[item])
          this.produtos.push(result[item]);
        });
        (this.produtos as any).sort((a, b) => {
          if (a.isShared === b.isShared) {
            return a.description.localeCompare(b.description);
          }
          return a.isShared ? -1 : 1;
        });
        //console.log"teste1000")
        resolve(this.produtos)
      }).catch(err => {
        this.carregando = false;
        Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
     });
      //console.log"hmhmhm", this.produtos)
    });
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  downloadPdf() {
    var prepare = [];
    this.produtos.forEach(e => {
      var tempObj = [];
      tempObj.push(e.id);
      tempObj.push(e.description);
      tempObj.push(e.unit['description']);
      tempObj.push(e.barcode);
      tempObj.push(e.ncm);
      prepare.push(tempObj);
    });
    const addFooters = doc => {
      const pageCount = doc.internal.getNumberOfPages()

      doc.setFont('helvetica', 'italic')
      doc.setFontSize(8)
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text('Página ' + String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width / 2, 287, {
          align: 'center'
        })
      }
    }
    const doc = new jsPDF("p", "mm", "a4");
    doc.setFontSize(10);
    doc.text(14, 10, 'PRODUTOS');
    doc.text(175, 10, moment().format('DD/MM/YY HH:mm').toString());
    doc.setLineWidth(0.3);
    doc.line(14, 12, 200, 12);
    doc.autoTable({
      head: [['ID', 'Produto', 'Unidade', 'Código de Barras', 'Código NCM']],
      body: prepare,
      headStyles: {
        fillColor: "#adacac",
        textColor: "#000000"
      },
    });
    addFooters(doc)

    doc.save('produtos' + '.pdf');
  }

  deleteProduto(row: any) {
    if(row.isShared == true && !this.checkPermission.canSetSharedItem()){
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível editar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
      return;
    }
    Swal.fire({
      title: 'Tem certeza que deseja deletar?',
      text: "Não há como reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, delete!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.carregando= true;
        this.productProvider.delProduct(row.id).then((result) => {
         this.generateTable();
         Swal.fire(
          'Sucesso!',
         'O produto foi deletado com sucesso!',
         'success'
      )
        }).catch(err => {
          this.carregando = false;
          Swal.fire({
            title: 'Falha!',
            text: err.error.message,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          })
        });

      }
    });

  }

  editProduto(row: any) {
    if(row.isShared == true && !this.checkPermission.canSetSharedItem()){
      Swal.fire({
        title: 'Atenção!',
        text: "Não é possível editar informações compartilhadas!",
        icon: 'warning',
        confirmButtonColor: '#3085d6',
      })
      return;
    }
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroProdutoComponent, modalOptions);
    modalRef.componentInstance.editar = true;
    modalRef.componentInstance.produtoExistente = row;
    modalRef.result.then((result) => {
      this.generateTable();
    }, () => {
    });
  }


  newProduto() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroProdutoComponent, modalOptions);
    modalRef.result.then((result) => {
      this.generateTable();
    }, () => {
      //this.generateTable();
    });
  }
}

class Unidade {
  id: number;
  description: string;
}

class Produto {
  id: number;
  description: string;
  unit: string;
  ncm: string;
  barcode: null;

}
