import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Snackbar} from '../../../../../../util/snackbar';
import {ErrorUtil} from '../../../../../../util/error';
import {ImovelDTO} from '../../../../../../model/dto/ImovelDTO';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {take, takeUntil} from 'rxjs/operators';
import {Country} from '../../../../../../model/basicos/Country';
import {PropertyProvider} from '../../../../../../providers/company/property/property';
import {MatDialog} from '@angular/material/dialog';
import {EstadosMunicipiosUtil} from '../../../../../../util/estados-municipios';
import {ReplaySubject, Subject} from 'rxjs';
import {Estate} from '../../../../../../model/basicos/Estate';
import {City} from '../../../../../../model/basicos/City';
import {Imovel} from '../../../../../../model/Imovel';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {UserProvider} from 'src/providers/user/user';
import Swal from 'sweetalert2';
import {CitiesProvider} from 'src/providers/basicos/cities';
import {StatesProvider} from 'src/providers/basicos/states';
import {CountriesProvider} from 'src/providers/basicos/countries';
import {Frete} from 'src/model/Frete';
import {Reboque} from 'src/model/Reboque';
import {CheckCNPJProvider} from '../../../../../../providers/basicos/checkCNPJ';
import {CheckPermission} from 'src/service/checkPermission';


@Component({
  selector: 'app-cadastro-destinatario',
  templateUrl: './cadastro-destinatario.component.html',
  styleUrls: ['./cadastro-destinatario.component.scss']
})
export class CadastroDestinatarioComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              public snackbar: Snackbar,
              public erroUtil: ErrorUtil,
              public fb: FormBuilder,
              private propertyProvider: PropertyProvider,
              private userProvider: UserProvider,
              public modalService: NgbModal,
              public dialog: MatDialog,
              public cnpjProvider: CheckCNPJProvider,
              public errorUtil: ErrorUtil,
              public estadosMunicipiosUtil: EstadosMunicipiosUtil,
              public citiesProvider: CitiesProvider,
              public statesProvider: StatesProvider,
              public countriesProvider: CountriesProvider,
              public http: HttpClient,
              public checkPermission: CheckPermission) {
  }

  @ViewChild('triggerCountry', {static: false}) triggerCountry: MatAutocompleteTrigger;
  @ViewChild('triggerCity', {static: false}) triggerCity: MatAutocompleteTrigger;
  @ViewChild('triggerState', {static: false}) triggerState: MatAutocompleteTrigger;
  @ViewChild('citySelect', {static: true}) citySelect: MatSelect;
  @ViewChild('stateSelect', {static: true}) stateSelect: MatSelect;
  @ViewChild('countrySelect', {static: true}) countrySelect: MatSelect;
  @Input() enableClearOnEscapePressed = true;
  @Input() public imovelExistente;
  @Input() public destinatarioExistente;

  public destinatario: Destinatario;
  public destinatarioAux: Destinatario = new Destinatario();
  public editar = false;
  public destinatarioForm: FormGroup;
  public destExistente;
  public pessoaFisica: FormGroup;
  public pessoaJuridica: FormGroup;
  public imovelForm: FormGroup;
  public freteForm: FormGroup;
  public reboqueForm: FormGroup;
  public ieType = "EXEMPTED_TAXPAYER";
  public enviado = false;
  public enviando = false;
  public imovel: ImovelDTO = new ImovelDTO();
  public imovelAux: Imovel = new Imovel();
  public freteAux: Frete = new Frete();
  public reboqueAux: Reboque = new Reboque();
  public codImovel: string;
  public municipioId: number;
  public properties: ImovelDTO[];
  public members: any[];
  public i: number;
  public imovelForm2;
  public pessoaType = '1';
  public countrys: Country[] = this.estadosMunicipiosUtil.paises;
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountrys: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  public states: Estate[] = this.estadosMunicipiosUtil.estados;
  public stateFilterCtrl: FormControl = new FormControl();
  public filteredStates: ReplaySubject<Estate[]> = new ReplaySubject<Estate[]>(1);
  public cities: City[] = this.estadosMunicipiosUtil.cidades;
  public cityFilterCtrl: FormControl = new FormControl();
  public filteredCities: ReplaySubject<City[]> = new ReplaySubject<City[]>(1);
  protected _onDestroyCountry = new Subject<void>();
  protected _onDestroyState = new Subject<void>();
  protected _onDestroyCity = new Subject<void>();
  public gringo = false;
  public persistInformation: boolean = true;
  public retirada: boolean = false;
  public recebimento: boolean = false;
  public frete: boolean;
  public clientInfo: boolean = false;
  public destinatarios;
  public destinatarioSelecionado;
  public reboqueFormGroup;
  public enderecos = [];
  //public imovelForm: FormGroup;
  public showIE = false;
  public reboque = false;
  private enderecoExclusao: Imovel[] = [];
  public isShared: boolean = false;


  ngOnDestroy() {
    this._onDestroyCountry.next();
    this._onDestroyCountry.complete();
    this._onDestroyState.next();
    this._onDestroyState.complete();
    this._onDestroyCity.next();
    this._onDestroyCity.complete();
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnInit() {
    this.configCountry();
    this.configState();

    this.pessoaType = '1';
    this.freteForm = this.fb.group({
      placaVeiculo: [''],
      ufVeiculo: [''],
      registroNacional: [''],
      valorSeguro: ['']
    });
    this.reboqueForm = this.fb.group({
      placaVeiculo: [''],
      estadoVeiculo: [''],
      registroNacional: [''],
      identificacaoVagao: [''],
      identificacaoBalsa: ['']
    });
    this.imovelForm = this.fb.group({
      dfeNumber: ['', Validators.required],
      serialNumber: ['', Validators.required],
      pais: ['', Validators.required],
      estado: ['', Validators.required],
      municipio: ['', Validators.required],
      neighborhood: ['', Validators.required],
      cep: ['', Validators.required],
      houseNumber: ['', Validators.required],
      complement: [''],
      street: ['', Validators.required]
    });
    this.destinatarioForm = this.fb.group({
      name: ['', Validators.required],
      cpfCNPJ: ['', Validators.required],
      telephone: [''],
      email: [''],
      inscricaoEstadual: ['']
    });
    this.pessoaJuridica = this.fb.group({
      razaoSocial: ['', Validators.required],
      inscricaoEstadual: [''],
      ieType: [''],
      inscricaoSUFRAMA: [''],
      substitutoTributario: ['']
    });

    this.reboqueFormGroup = new FormArray([]);

    if (this.editar == true) {
      this.pessoaType = this.returnPersonType();
      this.destinatarioForm.controls.name.setValue(this.destExistente.name);
      this.destinatarioForm.controls.cpfCNPJ.setValue(this.destExistente.cpfCNPJ);
      this.destinatarioForm.controls.telephone.setValue(this.destExistente.telephone);
      this.destinatarioForm.controls.email.setValue(this.destExistente.email);
      this.enderecos = this.destExistente.properties;
      if (this.pessoaType != '3') {
        this.imovelForm.controls.pais.setValue(this.destExistente.properties[0].countryId);
        this.imovelForm.controls.estado.setValue(this.destExistente.properties[0].ufId);
        this.getStateId();
        this.imovelForm.controls.municipio.setValue(this.destExistente.properties[0].cityId);
      }
      //  this.configCity()
      this.imovelForm.controls.neighborhood.setValue(this.destExistente.properties[0].neighborhood);
      this.imovelForm.controls.cep.setValue(this.destExistente.properties[0].cep);
      this.imovelForm.controls.street.setValue(this.destExistente.properties[0].street);
      if (this.destExistente.properties[0].ieType) {
        this.pessoaJuridica.controls.ieType.setValue(this.destExistente.properties[0].ieType);
      }
      if (this.destExistente.properties[0].ie && this.destExistente.cpfCNPJ.length == 14) {
        this.pessoaJuridica.controls.inscricaoEstadual.setValue(this.destExistente.properties[0].ie);
      } else if (this.destExistente.properties[0].ie) {
        this.destinatarioForm.controls.inscricaoEstadual.setValue(this.destExistente.properties[0].ie);
      }
      if (this.pessoaType == '2') {
        this.pessoaJuridica.controls.substitutoTributario.setValue(this.destExistente.taxSubstitute);
      }
      if (this.destExistente.properties[0].houseNumber) {
        this.imovelForm.controls.houseNumber.setValue(this.destExistente.properties[0].houseNumber);
      }
      if (this.destExistente.properties[0].complement) {
        this.imovelForm.controls.complement.setValue(this.destExistente.properties[0].complement);
      }
      this.isShared = this.destExistente.isShared;

      if (this.frete) {
        this.freteForm.patchValue({
          placaVeiculo: this.destExistente.frete[0].placaVeiculo,
          ufVeiculo: this.destExistente.frete[0].ufVeiculoId,
          registroNacional: this.destExistente.frete[0].registroNacional,
          valorSeguro: this.destExistente.frete[0].valorSeguro
        });

        if (this.destExistente.frete[0].reboque) {

          this.destExistente.frete[0].reboque.forEach(element => {
            this.reboqueFormGroup.push(
              new FormGroup({
                placaVeiculo: new FormControl(element.placaVeiculo),
                estadoVeiculo: new FormControl(element.estadoVeiculoId),
                registroNacional: new FormControl(element.registroNacional),
                identificacaoVagao: new FormControl(element.identificacaoVagao),
                identificacaoBalsa: new FormControl(element.identificacaoBalsa)
              })
            );
          });
        }
      }
    }


    this.imovelForm.controls.pais.setValue(32);
    if (this.frete) {
      this.imovelForm.controls.cep.clearValidators();
      this.imovelForm.controls.street.clearValidators();
      this.imovelForm.controls.neighborhood.clearValidators();
      this.imovelForm.controls.pais.clearValidators();
      this.imovelForm.controls.municipio.clearValidators();
    }
  }

  toggleClientInfo() {
    this.clientInfo = !this.clientInfo;
  }

  selectDestinatary($event) {
    this.destinatarioSelecionado = this.destinatarios.find(element => element.arrayKey == $event.value);
    if (this.destinatarioSelecionado.cpfCnpj.length == 11) {
      this.pessoaType = "1";
      this.destinatarioForm.controls.inscricaoEstadual.setValue(this.destinatarioSelecionado.enderecos[0].ie);
    } else {
      this.pessoaType = "2";
      this.pessoaJuridica.controls.ieType.clearValidators();
      this.pessoaJuridica.controls.ieType.setValue(this.destinatarioSelecionado.enderecos[0].ieType);
      this.pessoaJuridica.controls.inscricaoEstadual.clearValidators();
      this.pessoaJuridica.controls.inscricaoEstadual.setValue(this.destinatarioSelecionado.enderecos[0].ie);
    }

    //TEM QUE SETAR DUAS VEZES O CPF/CNPJ PARA FUNCIONAR
    this.destinatarioForm.controls.cpfCNPJ.setValue(this.destinatarioSelecionado.cpfCnpj);
    this.destinatarioForm.controls.cpfCNPJ.setValue(this.destinatarioSelecionado.cpfCnpj);


    this.destinatarioForm.controls.name.setValue(this.destinatarioSelecionado.nome);
    this.destinatarioForm.controls.inscricaoEstadual.setValue(this.destinatarioSelecionado.enderecos[0].ie);

    this.imovelForm.controls.cep.setValue(this.destinatarioSelecionado.enderecos[0].cep);
    this.imovelForm.controls.street.setValue(this.destinatarioSelecionado.enderecos[0].logradouro);
    this.imovelForm.controls.neighborhood.setValue(this.destinatarioSelecionado.enderecos[0].bairro);
    this.imovelForm.controls.houseNumber.setValue(this.destinatarioSelecionado.enderecos[0].numero);
    this.imovelForm.controls.complement.setValue(this.destinatarioSelecionado.enderecos[0].complemento);
    this.imovelForm.controls.pais.setValue(this.destinatarioSelecionado.enderecos[0].pais);
    this.imovelForm.controls.estado.setValue(this.destinatarioSelecionado.enderecos[0].uf);
    this.getStateId();
    this.imovelForm.controls.municipio.setValue(this.destinatarioSelecionado.enderecos[0].codigoMunicipio);
  }

  getCountryId() {
    this.imovelAux.countryId = this.imovelForm.controls.pais.value;
  }

  fixingCountryId() {
    this.enviado = false;
    this.destinatarioForm.controls.cpfCNPJ.patchValue(null);
    //console.log(this.pessoaType)
    if (this.imovelForm.controls.pais.value != 32) {
      this.imovelForm.controls.pais.setValue(32);
    }
  }

// Exibe mensagens de sucesso
  afterPostAndPutImovel(closeParameter, imovel, editMode: boolean) {
    if (editMode) {
      this.snackbar.openLong('Sucesso! ' + imovel.name + ' editado com sucesso!', 'success');
    } else {
      this.snackbar.openLong('Sucesso! ' + imovel.name + ' inserido com sucesso!', 'success');
    }
    this.activeModal.close(closeParameter);
  }

// --Não utilizado
  putProperty(property) {
    this.propertyProvider.putProperty(property).then((imovelPut: ImovelDTO) => {
      this.afterPostAndPutImovel(this.municipioId, imovelPut, true);
    }).catch((error: HttpErrorResponse) => {
      this.enviando = false;
      this.snackbar.openLong(this.errorUtil.checkErrorStatus(error, error.status, error.error, 'property'), 'erro');
    });
  }

// Realiza um put (em caso de edição) enviado dados de do destintario
  editDestinatario() {
    this.enviado = true;
    if (this.destinatarioForm.valid) {
      let dest;
      dest = this.setObjectEndereco();
      ////console.logdest)
      dest.id = this.destExistente.id;
      this.userProvider.putDestinatario(dest).then((resp) => {
        this.activeModal.close(resp);
      }).catch(err => {
        this.enviado = false;
        Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      });
    }
  }

  toggleReboque() {
    this.reboque = !this.reboque;
  }

  novoFormReboque() {
    //console.log("this.reboqueFormGroup", this.reboqueFormGroup);
    this.reboqueFormGroup.push(
      new FormGroup({
        placaVeiculo: new FormControl(''),
        estadoVeiculo: new FormControl(''),
        registroNacional: new FormControl(''),
        identificacaoVagao: new FormControl(''),
        identificacaoBalsa: new FormControl('')
      })
    );
    //console.log("this.reboqueFormGroup2", this.reboqueFormGroup);
  }

  removerReboque(i) {
    this.reboqueFormGroup.removeAt(i);
  }

  freteInfo() {
    this.freteAux.placaVeiculo = this.freteForm.controls.placaVeiculo.value;
    this.freteAux.ufVeiculoId = this.freteForm.controls.ufVeiculo.value;
    this.statesProvider.getStatesById(this.freteForm.controls.ufVeiculo.value.toString()).then((result: any) => {
      this.freteAux.ufVeiculo = result.name;
    });
    this.freteAux.registroNacional = this.freteForm.controls.registroNacional.value;
    this.freteAux.valorSeguro = this.freteForm.controls.valorSeguro.value;

    let reboqueArray = [];
    if (this.reboqueFormGroup.controls.length > 0) {
      this.reboqueFormGroup.controls.forEach(element => {
        let reboqueObj = {
          placaVeiculo: element.controls.placaVeiculo.value,
          estadoVeiculoId: element.controls.estadoVeiculo.value,
          registroNacional: element.controls.registroNacional.value,
          identificacaoVagao: element.controls.identificacaoVagao.value,
          identificacaoBalsa: element.controls.identificacaoBalsa.value
        };
        reboqueArray.push(reboqueObj);
      });
    }
    this.freteAux.reboque = reboqueArray;
    //console.log("this.freteAux.reboque ", this.freteAux.reboque)
  }

  changeFreteState(control) {
    //console.log("control", control);
    //console.log("do form", this.freteForm.controls.ufVeiculo.value);
  }

  noPersistInfo(object) {
    this.citiesProvider.getCityById(object.properties[0].cityId.toString()).then((result: any) => {
      object.properties[0].cityName = result.name;
      this.statesProvider.getStatesById(object.properties[0].ufId.toString()).then((result: any) => {
        object.properties[0].stateName = result.name;
        object.properties[0].stateAbr = result.abbreviation;
        this.countriesProvider.getCountryById(object.properties[0].countryId.toString()).then((result: any) => {
          object.properties[0].countryName = result.name;
          this.activeModal.close(object);
        });
      });
    });
  }

  // Realiza requisição de POST em caso de novo destinatario
  newDestinatario() {
    this.enviado = true;
    if (this.destinatarioForm.valid || this.frete) {
      let dest = this.setObjectEndereco();
      if (this.persistInformation == false) {
        this.noPersistInfo(dest);
        this.enviado = false;
      } else {
        this.userProvider.postDestinatario(dest).then((result) => {
          this.enviado = false;
          this.activeModal.close();
        }).catch(err => {
          this.enviado = false;
          Swal.fire({
            title: 'Falha!',
            text: err.error.message,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          });
        });
      }
    }
  }

// RETORNA json separando caso destinatario é gringo
  setObjectEndereco() {
    this.setImovelForm();
    this.freteInfo();
    let dest;
    if (this.pessoaType != "2") {
      dest = {
        // pessoaType: this.pessoaType,
        name: this.destinatarioForm.value.name,
        email: this.destinatarioForm.value.email,
        cpfCNPJ: this.destinatarioForm.value.cpfCNPJ,
        telephone: this.destinatarioForm.value.telephone,
        properties: [this.imovelAux]
      };
      if (this.frete) {
        dest.frete = [this.freteAux];
      }
      if (this.pessoaType == "3") {
        dest.foreignId = this.destinatarioForm.value.cpfCNPJ;
        dest.cpfCNPJ = "";
      }
    } else {
      dest = {
        //pessoaType: this.pessoaType,
        name: this.destinatarioForm.value.name,
        email: this.destinatarioForm.value.email,
        cpfCNPJ: this.destinatarioForm.value.cpfCNPJ,
        //razaoSocial:this.pessoaJuridica.value.name,
        //inscricaoSUFRAMA:this.pessoaJuridica.value.inscricaoSUFRAMA,
        taxSubstitute: this.pessoaJuridica.value.substitutoTributario,
        telephone: this.destinatarioForm.value.telephone,
        properties: [this.imovelAux],
        frete: [this.freteAux]
      };
    }
    this.noPersistInfo ? dest.isShared = this.isShared : null;
    dest = this.formatCpfCnpj(dest);
    return dest;
  }

  formatCpfCnpj(dest) {
    dest.cpfCNPJ = dest.cpfCNPJ.replace('/', '');
    dest.cpfCNPJ = dest.cpfCNPJ.replace('-', '');
    dest.cpfCNPJ = dest.cpfCNPJ.replace('.', '');
    dest.cpfCNPJ = dest.cpfCNPJ.replace('.', '');
    return dest;
  }


  protected setInitialValue() {

    this.filteredCountrys
      .pipe(take(1), takeUntil(this._onDestroyCountry))
      .subscribe(() => {
        this.countrySelect.compareWith = (a: Country, b: Country) => a && b && a.id === b.id;
      });

    if (this.editar) {

      this.filteredStates
        .pipe(take(1), takeUntil(this._onDestroyState))
        .subscribe(() => {
          this.stateSelect.compareWith = (a: Country, b: Country) => a && b && a.id === b.id;
        });

      this.filteredCities
        .pipe(take(1), takeUntil(this._onDestroyCity))
        .subscribe(() => {
          this.citySelect.compareWith = (a: Country, b: Country) => a && b && a.id === b.id;
        });

    }

  }

//configura o pais
  configCountry() {
    this.estadosMunicipiosUtil.setCountry();
    this.countrys = this.estadosMunicipiosUtil.paises;
    this.filteredCountrys.next(this.countrys.slice());
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyCountry))
      .subscribe(() => {
        this.filterCountrys();
      });
  }

//configura o estado
  configState() {
    this.estadosMunicipiosUtil.setState();
    this.states = this.estadosMunicipiosUtil.estados;
    this.filteredStates.next(this.states.slice());
    this.stateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyState))
      .subscribe(() => {

        this.filterStates();

      });
  }

//configura a cidade
  configCity() {
//console.log('aaaaa')
    this.cities = this.estadosMunicipiosUtil.cidades;
    this.filteredCities.next(this.cities.slice());
    this.cityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyCity))
      .subscribe(() => {
        this.filterCities();
      });
  }

//filtra cidades
  filterCities() {
    // console.log("2312sd12d1")

    if (!this.cities) {
      //console.log("aaa312312")
      return;
    }
    let search = this.cityFilterCtrl.value;
    if (!search) {
      //console.log("fon?")

      this.filteredCities.next(this.cities.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCities.next(
      this.cities.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  //filtra estados
  filterStates() {
    if (!this.states) {
      return;
    }
    let search = this.stateFilterCtrl.value;

    if (!search) {
      this.filteredStates.next(this.states.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredStates.next(
      this.states.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );

  }

//filtra paises
  filterCountrys() {
    if (!this.countrys) {
      return;
    }
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountrys.next(this.countrys.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCountrys.next(
      this.countrys.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  useCepToSetForm() {
    if (this.imovelForm.controls.cep.value.length == 8) {

      return new Promise((resolve, reject) => {

        this.http.get('https://viacep.com.br/ws/' + this.imovelForm.controls.cep.value + '/json/')
          .subscribe((result: any) => {
              resolve(result);
              this.imovelForm.controls.neighborhood.setValue(result.bairro);
              this.imovelForm.controls.street.setValue(result.logradouro);
              this.imovelAux.complement = result.complemento;
              this.states.forEach(element => {

                if (element.abbreviation == result.uf) {
                  this.imovelForm.controls.estado.setValue(element.id);
                  this.getStateId();
                  return true;
                }
              });
              this.cities.forEach(element => {
                if (element.name == result.localidade) {
                  this.imovelForm.controls.municipio.setValue(element.id);
                  return true;
                }
              });
            },
            (error) => {
              reject(error);
            });
      });
    }

  }

  getStateId() {
    //console.log('wtf?')
    this.imovelAux.ufId = this.imovelForm.controls.estado.value;
    this.estadosMunicipiosUtil.setCity(this.imovelForm.controls.estado.value);
    this.configCity();
  }

  returnPersonType() {
    if (this.destExistente.properties[0].countryId != 32 && this.destExistente.properties[0].countryId) {
      return "3";
    }
    if (this.destExistente.cpfCNPJ.length == 14) {
      return "2";
    } else {
      return "1";
    }
  }

  setImovelForm() {
    //console.log("imovelform", this.imovelForm);
    if (this.editar) {
      this.imovelAux.id = this.destExistente.properties[0].id;
    }
    if (this.imovelAux.countryId == 32) {
    } else {
      this.imovelForm.controls.cep.clearValidators();
    }
    if (this.pessoaType != "3") {
      this.imovelAux.cep = this.imovelForm.controls.cep.value;
    } else {
      this.imovelAux.cep = "00000";
    }

    this.imovelAux.neighborhood = this.imovelForm.controls.neighborhood.value;
    this.imovelAux.street = this.imovelForm.controls.street.value;
    this.imovelAux.countryId = this.imovelForm.controls.pais.value;
    this.imovelAux.houseNumber = this.imovelForm.controls.houseNumber.value;
    this.imovelAux.complement = this.imovelForm.controls.complement.value;
    //this.imovelAux.companyId = +localStorage.getItem('idEmpresa');

    if (this.pessoaType == "2") {
      this.imovelAux.ieType = (this.pessoaJuridica.controls.ieType.value) != "" ? this.pessoaJuridica.controls.ieType.value : null;
      this.imovelAux.ie = this.pessoaJuridica.controls.inscricaoEstadual.value;
    } else if (this.pessoaType == "1" && this.destinatarioForm.controls.inscricaoEstadual.value != '') {
      this.imovelAux.ie = this.destinatarioForm.controls.inscricaoEstadual.value;
      this.imovelAux.ieType = null;
    } else {
      this.imovelAux.ie = null;
      this.imovelAux.ieType = null;
    }
    if (this.pessoaType != "3") {
      this.imovelAux.ufId = this.imovelForm.controls.estado.value;
      this.imovelAux.cityId = this.imovelForm.controls.municipio.value;
    }

  }

  checkCNPJ() {
    let cnpj = this.destinatarioForm.get('cpfCNPJ').value;
    cnpj = cnpj.replace(/[^\d]+/g, '');

    this.enviando = true;
    this.cnpjProvider.getCNPJWS(cnpj).then((result: any) => {
      let inscricao = "";
      if (result.estabelecimento.inscricoes_estaduais.length > 0) {
        inscricao = result.estabelecimento.inscricoes_estaduais[0].inscricao_estadual;
      }
      if(result.razao_social.length > 60){
        this.snackbar.openLong("A Razão Social encontrada excede 60 caracteres. Verifique antes de enviar.", 'erro');
      }
      this.destinatarioForm.patchValue({
        name: result.razao_social,
        telephone: result.estabelecimento.ddd1 != null && result.estabelecimento.telefone1 != null ? result.estabelecimento.ddd1 + result.estabelecimento.telefone1 : null,
        email: result.email != null ? result.email : null
      });
      this.pessoaJuridica.get('inscricaoEstadual').setValue(inscricao);

      let estado = this.states.find(element => element.id == result.estabelecimento.estado.ibge_id).id;

      this.imovelForm.patchValue({
        cep: result.estabelecimento.cep,
        houseNumber: result.estabelecimento.numero == "S/N" ? null : result.estabelecimento.numero,
        neighborhood: result.estabelecimento.bairro,
        street: result.estabelecimento.tipo_logradouro + " " + result.estabelecimento.logradouro,
        complement: result.estabelecimento.complemento,
        estado: estado
      });
      this.getStateId();
      let cidade = this.cities.find(element => element.id == result.estabelecimento.cidade.ibge_id).id;
      this.imovelForm.get('municipio').setValue(cidade);
      this.enviando = false;
    }).catch((response) => {
      this.enviando = false;
      console.log(response);
      this.snackbar.openLong(response.error.titulo + ". Preencha os campos manualmente!", 'danger');
    });
  }

}

export class Destinatario {
  name: String;
  email: String;
  cpfCNPJ: String;
  properties: any;
  frete: any;
}
