import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {CadastroImoveisComponent} from '../cadastro-imoveis/cadastro-imoveis.component';
import {HttpClient} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {CertificadoProvider} from '../../../../../providers/certificado/certificadoProvider';
import {Snackbar} from '../../../../../util/snackbar';
import {MatDialog} from '@angular/material/dialog';
import {PropertyProvider} from '../../../../../providers/company/property/property';
import {ErrorUtil} from '../../../../../util/error';
import {ImovelDTO} from '../../../../../model/dto/ImovelDTO';
import {PessoaFiscalDTO} from '../../../../../model/dto/PessoaFiscalDTO';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserProvider} from '../../../../../providers/user/user';
import {MemberProvider} from '../../../../../providers/company/member/member';
import {CnpjValidator, CpfValidator} from 'src/util/customValidators/cpfCnpjValidator';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-emitentes',
    templateUrl: './emitentes.component.html',
    styleUrls: ['./emitentes.component.scss']
})

export class EmitentesComponent implements OnInit {
  @ViewChild("paginatorImoveis", {static: false}) paginatorImoveis: MatPaginator;
  @ViewChild("paginator", {static: false}) paginator: MatPaginator;
  @ViewChild('firstTableSort', {static: false}) public firstTableSort: MatSort;
  @ViewChild('secondTableSort', {static: false}) public secondTableSort: MatSort;
  @ViewChild('thirdTableSort', {static: false}) public thirdTableSort: MatSort;
  @ViewChild('certificadoInput', {static: false}) public certificadoButton;

  public displayedColumns = ['editar', 'excluir', 'name', 'cpfCNPJ', 'status', 'opcoes', 'convite'];
  public displayedColumnsImoveis = ['editar', 'excluir', 'propertyCode', 'name', 'default'];
  public pessoaFiscal: PessoaFiscalDTO[];
  public imoveis: ImovelDTO[];
  public dataSource: MatTableDataSource<PessoaFiscalDTO>;
  public dataSourceImovel: MatTableDataSource<ImovelDTO>;
  public erroEmitente: boolean = false;
  public erroImovel: boolean = false;
  public erro: string;
  public editarCertificado: boolean = false;
  public enviando: boolean[];
  public enviado: boolean = false;
  public enviandoRequisicao: boolean = true;
  public farmerUsers: any[];
  public emitter: any[];
  public property: any[];
  private carregando: Boolean = true;
  public emitenteForm: FormGroup;
  public emitente;
  //public taxRegimes
  public taxRegime = [];
  constructor(public http: HttpClient,
              private certificadoProvider: CertificadoProvider,
              private propertyProvider: PropertyProvider,
              private memberProvider: MemberProvider,
              private userProvider: UserProvider,
              public errorUtil: ErrorUtil,
              public fb: FormBuilder,
              public dialog: MatDialog,
              public snackbar: Snackbar,
              public modalService: NgbModal) {
   // this.getFarmerUserPessoaFiscal();

  }

  ngOnInit() {
    try{
       this.emitenteForm = this.fb.group({
      name: ['', Validators.required],
      cpfCnpj: ['', Validators.compose( [Validators.required, CnpjValidator, CpfValidator])],
      taxRegime: [''],
      companyName: [''],
    });

    }catch(error){

    }
    //this.enviandoRequisicao = true;
    this.getFarmerUserPessoaFiscal().then(() =>{
    this.setForm();
    this.enviandoRequisicao = false;
    })

  }
//
  editarInformacoes(){
    this.enviado = true;
    if(this.emitenteForm.invalid){
      this.snackbar.openLong("Preencha todos os campos corretamente!", 'warning');
      return;
    }
 this.enviandoRequisicao = true;
    this.userProvider.postEmissor(this.emitenteForm.value).then((result: any) => {
      this.emitente = result;
      this.enviandoRequisicao = false;
      this.snackbar.openLong("Sucesso! Informações alteradas com sucesso!", 'success');
      }).catch(err => {
        this.enviandoRequisicao = false;
        Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
     });

  }
// Recebe as informações do usuario
  getFarmerUserPessoaFiscal() {
    return new Promise((resolve, reject) => {
      this.enviandoRequisicao = true;
    this.userProvider.getEmitente(localStorage.getItem('userId')).then((result: any) => {
      this.emitente = result;
      // Recebe os possiveis tax regimes no select
      this.userProvider.getAllTaxRegimes().then((result2: any) => {
      ////console.logresult2);
      Object.keys(result2).forEach((item) => {
            this.taxRegime.push(result2[item]);
      })
      // Setas as posiveis opções de tax regime para o select
      this.emitenteForm.controls.taxRegime.setValue(this.emitente.taxRegime.value);
        this.enviandoRequisicao = false;
      }).catch(err => {
        this.enviandoRequisicao = false;
        Swal.fire({
          title: 'Falha!',
          text: err.error.message,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        })
     });
     resolve(this.emitente);
    }).catch(err => {
      this.enviandoRequisicao = false;
      Swal.fire({
        title: 'Falha!',
        text: err.error.message,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      })
   });
  })
  }

// Seta as informações do usuario no formulario
setForm(){
  this.enviandoRequisicao = true;
      this.emitenteForm.controls.name.setValue(this.emitente.name)
      this.emitenteForm.controls.cpfCnpj.setValue(this.emitente.cpfCnpj)
      this.emitenteForm.controls.taxRegime.setValue(this.emitente.taxRegime)
      this.emitenteForm.controls.companyName.setValue(this.emitente.companyName)
      this.enviandoRequisicao = false;
}

// Exibe swal de confirmação caso o usuario confirme realiza requisição para deletar o endereço
  deleteEndereco(a){
    Swal.fire({
      title: 'Tem certeza que deseja deletar essa inscrição estadual?',
      text: "Não há como reverter isso!",
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, delete!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.enviandoRequisicao = true;
        this.carregando= true;
        this.userProvider.delEndereco(a).then((result) => {
         //this.generateTable();

         Swal.fire(
          'Sucesso!',
         'a Inscrição Estadual foi deletada!',
         'success'
      )
      this.enviandoRequisicao = false;
      this.getFarmerUserPessoaFiscal().then(() =>{
        this.setForm();
        })
         }).catch(err => {
          this.enviandoRequisicao = false;
          this.carregando = false;
          Swal.fire({
            title: 'Falha!',
            text: err.error.message,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          })
       });

      }
    });
  }

  newImovel() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroImoveisComponent, modalOptions);
    modalRef.componentInstance.emitente = true;
    modalRef.result.then((result) => {
      this.getFarmerUserPessoaFiscal().then(() =>{
        this.setForm();
        })
      Swal.fire(
        'Sucesso!',
       'Inscrição Estadual adicionada com sucesso!',
       'success'
    )

     // this.property.push(result);
    }, () => {
    });
  }
/*
    Chama modal (CadastroImoveisComponent) com especificações
    modalRef.componentInstance.editar = true;
    modalRef.componentInstance.imovelExistente = row;
    */
  editImovel(row: any) {
    //console.logrow)
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroImoveisComponent, modalOptions);
    modalRef.componentInstance.emitente = true;
    modalRef.componentInstance.editar = true;
    modalRef.componentInstance.imovelExistente = row;
    //console.log);
    modalRef.result.then((result) => {
    //  this.property.push(result);
      Swal.fire(
        'Sucesso!',
       'Inscrição Estadual editada com sucesso!',
       'success'
    )
    this.getFarmerUserPessoaFiscal().then(() =>{
      this.setForm();
      })
    }, () => {
    });
  }


}
